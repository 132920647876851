import React from "react";
import Header from "../../Components/Header/Header";
import SideMenu from "../../Components/SideMenu/SideMenu";
import Dashboard from "../../Components/Dashboard/Dashboard";
import Footer from "../../Components/Footer";

const HomePage = () => {
  return (
    <div data-sidebar="dark">
      <div id="layout-wrapper">
        <Header />
        <SideMenu />
        <Dashboard />
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
