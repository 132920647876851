import React from "react";
import HeaderComp from "../../Components/HeaderComp";

const Analytics = () => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Analytics" />
          <div className="row" style={{ margin: "2rem" }}>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Number of Sales
                      </p>
                      <h4 className="mb-0">1452</h4>
                    </div>
                    <div className="text-primary ms-auto">
                      <i className="ri-stack-line font-size-24"></i>
                    </div>
                  </div>
                </div>

                <div className="card-body border-top py-3">
                  <div className="text-truncate">
                    <span className="badge badge-soft-success font-size-11">
                      <i className="mdi mdi-menu-up"> </i> 2.4%{" "}
                    </span>
                    <span className="text-muted ms-2">
                      From previous period
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Sales Revenue
                      </p>
                      <h4 className="mb-0">$ 38452</h4>
                    </div>
                    <div className="text-primary ms-auto">
                      <i className="ri-store-2-line font-size-24"></i>
                    </div>
                  </div>
                </div>
                <div className="card-body border-top py-3">
                  <div className="text-truncate">
                    <span className="badge badge-soft-success font-size-11">
                      <i className="mdi mdi-menu-up"> </i> 2.4%{" "}
                    </span>
                    <span className="text-muted ms-2">
                      From previous period
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Average Price
                      </p>
                      <h4 className="mb-0">$ 15.4</h4>
                    </div>
                    <div className="text-primary ms-auto">
                      <i className="ri-briefcase-4-line font-size-24"></i>
                    </div>
                  </div>
                </div>
                <div className="card-body border-top py-3">
                  <div className="text-truncate">
                    <span className="badge badge-soft-success font-size-11">
                      <i className="mdi mdi-menu-up"> </i> 2.4%{" "}
                    </span>
                    <span className="text-muted ms-2">
                      From previous period
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="dropdown float-end">
                    <a
                      href="#"
                      className="dropdown-toggle arrow-none card-drop"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="mdi mdi-dots-vertical"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end">
                      <a href="javascript:void(0);" className="dropdown-item">
                        Sales Report
                      </a>
                      <a href="javascript:void(0);" className="dropdown-item">
                        Export Report
                      </a>
                      <a href="javascript:void(0);" className="dropdown-item">
                        Profit
                      </a>
                      <a href="javascript:void(0);" className="dropdown-item">
                        Action
                      </a>
                    </div>
                  </div>

                  <h4 className="card-title mb-4">Latest Transactions</h4>

                  <div className="table-responsive">
                    <table
                      className="table table-centered datatable dt-responsive nowrap"
                      data-bs-page-length="5"
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead className="table-light">
                        <tr>
                          <th style={{ width: "20px" }}>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="ordercheck"
                              />
                              <label
                                className="form-check-label mb-0"
                                for="ordercheck"
                              >
                                &nbsp;
                              </label>
                            </div>
                          </th>
                          <th>Order ID</th>
                          <th>Date</th>
                          <th>Billing Name</th>
                          <th>Total</th>
                          <th>Payment Status</th>
                          <th style={{ width: "120px" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="ordercheck1"
                              />
                              <label
                                className="form-check-label mb-0"
                                for="ordercheck1"
                              >
                                &nbsp;
                              </label>
                            </div>
                          </td>

                          <td>
                            <a
                              href="javascript: void(0);"
                              className="text-dark fw-bold"
                            >
                              #NZ1572
                            </a>{" "}
                          </td>
                          <td>04 Apr, 2020</td>
                          <td>Walter Brown</td>

                          <td>$172</td>
                          <td>
                            <div className="badge badge-soft-success font-size-12">
                              Paid
                            </div>
                          </td>
                          <td id="tooltip-container1">
                            <a
                              href="javascript:void(0);"
                              className="me-3 text-primary"
                              data-bs-container="#tooltip-container1"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                            >
                              <i className="mdi mdi-pencil font-size-18"></i>
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="text-danger"
                              data-bs-container="#tooltip-container1"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                            >
                              <i className="mdi mdi-trash-can font-size-18"></i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="ordercheck2"
                              />
                              <label
                                className="form-check-label mb-0"
                                for="ordercheck2"
                              >
                                &nbsp;
                              </label>
                            </div>
                          </td>

                          <td>
                            <a
                              href="javascript: void(0);"
                              className="text-dark fw-bold"
                            >
                              #NZ1571
                            </a>{" "}
                          </td>
                          <td>03 Apr, 2020</td>
                          <td>Jimmy Barker</td>

                          <td>$165</td>
                          <td>
                            <div className="badge badge-soft-warning font-size-12">
                              unpaid
                            </div>
                          </td>
                          <td id="tooltip-container2">
                            <a
                              href="javascript:void(0);"
                              className="me-3 text-primary"
                              data-bs-container="#tooltip-container2"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                            >
                              <i className="mdi mdi-pencil font-size-18"></i>
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="text-danger"
                              data-bs-container="#tooltip-container2"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                            >
                              <i className="mdi mdi-trash-can font-size-18"></i>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="ordercheck3"
                              />
                              <label
                                className="form-check-label mb-0"
                                for="ordercheck3"
                              >
                                &nbsp;
                              </label>
                            </div>
                          </td>

                          <td>
                            <a
                              href="javascript: void(0);"
                              className="text-dark fw-bold"
                            >
                              #NZ1570
                            </a>{" "}
                          </td>
                          <td>03 Apr, 2020</td>
                          <td>Donald Bailey</td>

                          <td>$146</td>
                          <td>
                            <div className="badge badge-soft-success font-size-12">
                              Paid
                            </div>
                          </td>
                          <td id="tooltip-container3">
                            <a
                              href="javascript:void(0);"
                              className="me-3 text-primary"
                              data-bs-container="#tooltip-container3"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                            >
                              <i className="mdi mdi-pencil font-size-18"></i>
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="text-danger"
                              data-bs-container="#tooltip-container3"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                            >
                              <i className="mdi mdi-trash-can font-size-18"></i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="ordercheck4"
                              />
                              <label
                                className="form-check-label mb-0"
                                for="ordercheck4"
                              >
                                &nbsp;
                              </label>
                            </div>
                          </td>

                          <td>
                            <a
                              href="javascript: void(0);"
                              className="text-dark fw-bold"
                            >
                              #NZ1569
                            </a>{" "}
                          </td>
                          <td>02 Apr, 2020</td>
                          <td>Paul Jones</td>

                          <td>$183</td>
                          <td>
                            <div className="badge badge-soft-success font-size-12">
                              Paid
                            </div>
                          </td>
                          <td id="tooltip-container41">
                            <a
                              href="javascript:void(0);"
                              className="me-3 text-primary"
                              data-bs-container="#tooltip-container41"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                            >
                              <i className="mdi mdi-pencil font-size-18"></i>
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="text-danger"
                              data-bs-container="#tooltip-container41"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                            >
                              <i className="mdi mdi-trash-can font-size-18"></i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="ordercheck5"
                              />
                              <label
                                className="form-check-label mb-0"
                                for="ordercheck5"
                              >
                                &nbsp;
                              </label>
                            </div>
                          </td>

                          <td>
                            <a
                              href="javascript: void(0);"
                              className="text-dark fw-bold"
                            >
                              #NZ1568
                            </a>{" "}
                          </td>
                          <td>01 Apr, 2020</td>
                          <td>Jefferson Allen</td>

                          <td>$160</td>
                          <td>
                            <div className="badge badge-soft-danger font-size-12">
                              Chargeback
                            </div>
                          </td>
                          <td id="tooltip-container4">
                            <a
                              href="javascript:void(0);"
                              className="me-3 text-primary"
                              data-bs-container="#tooltip-container4"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                            >
                              <i className="mdi mdi-pencil font-size-18"></i>
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="text-danger"
                              data-bs-container="#tooltip-container4"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                            >
                              <i className="mdi mdi-trash-can font-size-18"></i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="ordercheck6"
                              />
                              <label
                                className="form-check-label mb-0"
                                for="ordercheck6"
                              >
                                &nbsp;
                              </label>
                            </div>
                          </td>

                          <td>
                            <a
                              href="javascript: void(0);"
                              className="text-dark fw-bold"
                            >
                              #NZ1567
                            </a>{" "}
                          </td>
                          <td>31 Mar, 2020</td>
                          <td>Jeffrey Waltz</td>

                          <td>$105</td>
                          <td>
                            <div className="badge badge-soft-warning font-size-12">
                              unpaid
                            </div>
                          </td>
                          <td id="tooltip-container5">
                            <a
                              href="javascript:void(0);"
                              className="me-3 text-primary"
                              data-bs-container="#tooltip-container5"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                            >
                              <i className="mdi mdi-pencil font-size-18"></i>
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="text-danger"
                              data-bs-container="#tooltip-container5"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                            >
                              <i className="mdi mdi-trash-can font-size-18"></i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="ordercheck7"
                              />
                              <label
                                className="form-check-label mb-0"
                                for="ordercheck7"
                              >
                                &nbsp;
                              </label>
                            </div>
                          </td>

                          <td>
                            <a
                              href="javascript: void(0);"
                              className="text-dark fw-bold"
                            >
                              #NZ1566
                            </a>{" "}
                          </td>
                          <td>30 Mar, 2020</td>
                          <td>Jewel Buckley</td>

                          <td>$112</td>
                          <td>
                            <div className="badge badge-soft-success font-size-12">
                              Paid
                            </div>
                          </td>
                          <td id="tooltip-container6">
                            <a
                              href="javascript:void(0);"
                              className="me-3 text-primary"
                              data-bs-container="#tooltip-container6"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                            >
                              <i className="mdi mdi-pencil font-size-18"></i>
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="text-danger"
                              data-bs-container="#tooltip-container6"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                            >
                              <i className="mdi mdi-trash-can font-size-18"></i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="ordercheck8"
                              />
                              <label
                                className="form-check-label mb-0"
                                for="ordercheck8"
                              >
                                &nbsp;
                              </label>
                            </div>
                          </td>

                          <td>
                            <a
                              href="javascript: void(0);"
                              className="text-dark fw-bold"
                            >
                              #NZ1565
                            </a>{" "}
                          </td>
                          <td>29 Mar, 2020</td>
                          <td>Jamison Clark</td>

                          <td>$123</td>
                          <td>
                            <div className="badge badge-soft-success font-size-12">
                              Paid
                            </div>
                          </td>
                          <td id="tooltip-container7">
                            <a
                              href="javascript:void(0);"
                              className="me-3 text-primary"
                              data-bs-container="#tooltip-container7"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                            >
                              <i className="mdi mdi-pencil font-size-18"></i>
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="text-danger"
                              data-bs-container="#tooltip-container7"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                            >
                              <i className="mdi mdi-trash-can font-size-18"></i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="ordercheck9"
                              />
                              <label
                                className="form-check-label mb-0"
                                for="ordercheck9"
                              >
                                &nbsp;
                              </label>
                            </div>
                          </td>

                          <td>
                            <a
                              href="javascript: void(0);"
                              className="text-dark fw-bold"
                            >
                              #NZ1564
                            </a>{" "}
                          </td>
                          <td>28 Mar, 2020</td>
                          <td>Eddy Torres</td>

                          <td>$141</td>
                          <td>
                            <div className="badge badge-soft-success font-size-12">
                              Paid
                            </div>
                          </td>
                          <td id="tooltip-container8">
                            <a
                              href="javascript:void(0);"
                              className="me-3 text-primary"
                              data-bs-container="#tooltip-container8"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                            >
                              <i className="mdi mdi-pencil font-size-18"></i>
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="text-danger"
                              data-bs-container="#tooltip-container8"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                            >
                              <i className="mdi mdi-trash-can font-size-18"></i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="ordercheck10"
                              />
                              <label
                                className="form-check-label mb-0"
                                for="ordercheck10"
                              >
                                &nbsp;
                              </label>
                            </div>
                          </td>

                          <td>
                            <a
                              href="javascript: void(0);"
                              className="text-dark fw-bold"
                            >
                              #NZ1563
                            </a>{" "}
                          </td>
                          <td>28 Mar, 2020</td>
                          <td>Frank Dean</td>

                          <td>$164</td>
                          <td>
                            <div className="badge badge-soft-warning font-size-12">
                              unpaid
                            </div>
                          </td>
                          <td id="tooltip-container9">
                            <a
                              href="javascript:void(0);"
                              className="me-3 text-primary"
                              data-bs-container="#tooltip-container9"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                            >
                              <i className="mdi mdi-pencil font-size-18"></i>
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="text-danger"
                              data-bs-container="#tooltip-container9"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                            >
                              <i className="mdi mdi-trash-can font-size-18"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
