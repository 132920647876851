import axios from 'axios';
import { PERCENTAGEURL } from '../constants/constants';

export const getPercentageList = async (company) => {
    const det = {company: company};
    const catelist = await axios.post(`${PERCENTAGEURL}/getpercentage`, det).then( res => res.data);
    return catelist;
}

export const addPercentageList = async (company, data) => {
    const det = {company: company, update: data};
    const catelist = await axios.post(`${PERCENTAGEURL}/addpercentage`, det).then( res => res.data);
    return catelist;
}

// export const getProducts = async (mainselect, subselect, lowselect)  => {  
//     const prodselect = {main_cat: mainselect, sub_cat: subselect, low_cat: lowselect}
//     const catelist = await axios.post(`${DOBAURL}/dobaproducts/`, prodselect ).then( res => res.data);
//     return catelist;
// }