import React, { useState } from "react";
import HeaderComp from "../../Components/HeaderComp";
import { getOrdersById, getUserbyPhone, updateOrder } from "../../api/EmartApi";
import { rupee } from "../../constants/priceFormats";
import Popup from "../../Components/Popup/Popup";

const EmartFindUser = () => {
  const [phone, setPhone] = useState();
  const [user, setUser] = useState({});
  const [orders, setOrders] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [openData, setOpenData] = useState({});

  const handleStatusChange = async (orid, spuid) => {

    const res = await updateOrder(orid, spuid);
    setIsOpen(false);
    handleUser();

  }


  const closePopup = () => {
    setIsOpen(false);
  };

  const statusValue = [ "Order Placed", "Order Shipped", "Out For Delivery", "Delivered", "Cancelled"]

 

  const handlePopup = (data) =>{
    setIsOpen(true)
    setOpenData(data)
  }

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = ("0" + date.getDate()).slice(-2); 
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    
    return `${day}/${month}/${year}`;
  }


  const handleUser = async () => {
    const res = await getUserbyPhone(phone);
    setUser(res);
    const orders = await getOrdersById(res._id);
    setOrders(orders.data);
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Find User" />

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Find Member </h4>
                  <p>Find Member with Phone Number </p>
                  <div className="row">
                    <div className="col-sm-12">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Phone Number"
                        id="example-text-input"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "1rem" }}>
                    <div className="col-sm-12">
                      <button
                        className="btn btn-primary"
                        onClick={() => handleUser()}
                      >
                        Find User
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {user && (
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h6>{user?.merci_full_name}</h6>
                    <h6>{user?.merci_phone}</h6>
                    <h6>{user?.merci_email}</h6>
                    <h6>{user?.merci_dob}</h6>
                    <h6>{user?.merci_gender}</h6>
                  </div>
                </div>
              </div>
            </div>
          )}
          { orders &&
          <>
             <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>ORID</th>
                          <th>Order ID</th>
                          <th>Payment ID</th>
                          <th>Purchased Date</th>
                          <th>Total Amount</th>
                          {/* <th>Change Status</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {orders?.map((letter, ind) => {
                          return (
                            <tr>
                              <td className='tdhead' onClick={() =>handlePopup(letter)}>{letter.ORID}</td>
                              <td>{letter.orderID}</td>
                              <td>{letter.paymentID}</td>
                              <td>{formatDate(letter.purchasedDate)}</td>
                              <td>{ rupee.format(letter.total)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            isOpen &&
          <Popup isOpen={isOpen} onClose={closePopup}>
            <div style={{margin: '1rem'}}>
            <h6>{openData.address.merci_name}</h6>
            <h6>{openData.address.merci_mobile}</h6>
            <h6>{`${openData.address.merci_address}, ${openData.address.merci_city}, 
            ${openData.address.merci_state}.${openData.address.merci_pincode} `}</h6>
            <table className="table mb-0" style={{tableLayout: 'fixed'}}>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Name</th>
                          <th>SPU ID</th>
                          <th>Status</th>
                          <th>Amount</th>
                          <th>Change Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {openData.cartData.map((data, ind) => {
                          return (
                            <tr key={ind}>
                              <td><img src={data.merci_prod_img} alt="product img" width="100px"  height="100px"/></td>
                              <td>{data.merci_prod_name}</td>
                              <td>{data.merci_spu_id}</td>
                              <td>{statusValue[data.status-1]}</td>
                              <td>{data.merci_mrp}</td>
                              <td><button onClick={() => handleStatusChange(openData.ORID, data.merci_spu_id)}>Update Status</button></td>
                            </tr>

                        )})}
                        
                      </tbody>
                    </table>
            </div>
          </Popup>
}
</>
}
        </div>
      </div>
    </div>
  );
};

export default EmartFindUser;
