import React from 'react'
import HeaderComp from '../../Components/HeaderComp'

const NotificationsRooftop = () => {
  return (
    <div className="main-content">
    <div className="page-content">
      <div className="container-fluid">
        <HeaderComp title="Notifications" />
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Notifications</h4>

                {/* <div className="table-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Shop Name</th>
                        <th>User Name</th>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>Payment</th>
                        <th>Status</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {shops &&
                        shops.map((shop, index) => {
                          return (
                            <tr key={index}>
                              <td>{shop.merci_shop_name}</td>
                              <td>{shop.merci_user_name}</td>
                              <td>{shop.merci_full_name}</td>
                              <td>{shop.merci_email}</td>
                              <td>{shop.merci_phone}</td>
                              <td>{`${shop.merci_mandal}, ${shop.merci_district}, ${shop.merci_state}`}</td>
                              <td> <button className={shop.merci_isPayment? 'btn btn-success waves-effect waves-light'
                              : 'btn btn-danger waves-effect waves-light'}>
                                {shop.merci_isPayment ? 'Done' : 'Not Done'}
                              </button>
                              </td>
                              <td>
                                  <i className="fas fa-edit" onClick={() => onCheckChange(shop._id, shop.merci_isActive)} style={{cursor: 'pointer'}}></i>{" "}
                                  {shop.merci_isActive ? 'Active' : 'Not Active'}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-danger waves-effect waves-light"
                                >
                                  <i className="ri-close-line align-middle me-2"></i>{" "}
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default NotificationsRooftop