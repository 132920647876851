import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { getDobaProducts } from '../../api/DobaApi';
// import { getMeeshoProducts } from '../../api/MeeshoApi';
import { getCJProducts } from '../../api/CJApi';
// import { getDeodapProducts } from '../../api/DeodapApi';

const initialState = {
  loading: false,
  products: {},
  error: ''
};

// pass aruguments in below getProducts?
export const getProducts = createAsyncThunk(
  'product/fetchProducts',
  async ({company, main, sub, low, page, pageSize}) => {
    let products = [];
    switch (company) {
      case 'doba':
        // products = await getDobaProducts(main, sub, low, page, pageSize)
         break;
      case 'cj':
        products = await getCJProducts(main, sub, low, page, pageSize)
         break;
      case 'meesho':
        // products = await getMeeshoProducts(main, sub, low, page, pageSize)
         break;
      case 'deodap':
          // products = await getDeodapProducts(main, sub, low, page, pageSize)
           break;
      default:
         console.log("No valid comparison was made");
     }
    
    return products;
  }
);

export const productSlice = createSlice({
  name: 'product',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.loading = false;
        state.products = {};
        state.error = action.payload;
      })
  },
});

export default productSlice.reducer;
