import React from "react";
import HeaderComp from "../../Components/HeaderComp";

const Email = () => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Inbox" />
          <div class="row">
            <div class="col-12">
              <div class="email-leftbar card">
                <div class="d-grid">
                  <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light"
                    data-bs-toggle="modal"
                    data-bs-target="#composemodal"
                  >
                    Compose
                  </button>
                </div>
                <div class="mail-list mt-4">
                  <a href="#" class="active">
                    <i class="mdi mdi-email-outline me-2"></i> Inbox{" "}
                    <span class="ms-1 float-end">(0)</span>
                  </a>
                  <a href="#">
                    <i class="mdi mdi-star-outline me-2"></i>Starred
                  </a>
                  <a href="#">
                    <i class="mdi mdi-diamond-stone me-2"></i>Important
                  </a>
                  <a href="#">
                    <i class="mdi mdi-file-outline me-2"></i>Draft
                  </a>
                  <a href="#">
                    <i class="mdi mdi-email-check-outline me-2"></i>Sent Mail
                  </a>
                  <a href="#">
                    <i class="mdi mdi-trash-can-outline me-2"></i>Trash
                  </a>
                </div>

                <h6 class="mt-4">Labels</h6>

                <div class="mail-list mt-1">
                  <a href="#">
                    <span class="mdi mdi-circle-outline text-info float-end"></span>
                    Theme Support
                  </a>
                  <a href="#">
                    <span class="mdi mdi-circle-outline text-warning float-end"></span>
                    Freelance
                  </a>
                  <a href="#">
                    <span class="mdi mdi-circle-outline text-primary float-end"></span>
                    Social
                  </a>
                  <a href="#">
                    <span class="mdi mdi-circle-outline text-danger float-end"></span>
                    Friends
                  </a>
                  <a href="#">
                    <span class="mdi mdi-circle-outline text-success float-end"></span>
                    Family
                  </a>
                </div>

                <h6 class="mt-4">Chat</h6>
              </div>
              <div class="email-rightbar mb-3">
                <div class="card">
                  <div class="btn-toolbar p-3" role="toolbar">
                    <div class="btn-group me-2 mb-2 mb-sm-0">
                      <button
                        type="button"
                        class="btn btn-primary waves-light waves-effect"
                      >
                        <i class="fa fa-inbox"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary waves-light waves-effect"
                      >
                        <i class="fa fa-exclamation-circle"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary waves-light waves-effect"
                      >
                        <i class="far fa-trash-alt"></i>
                      </button>
                    </div>
                    <div class="btn-group me-2 mb-2 mb-sm-0">
                      <button
                        type="button"
                        class="btn btn-primary waves-light waves-effect dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-folder"></i>{" "}
                        <i class="mdi mdi-chevron-down ms-1"></i>
                      </button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#">
                          Updates
                        </a>
                        <a class="dropdown-item" href="#">
                          Social
                        </a>
                        <a class="dropdown-item" href="#">
                          Team Manage
                        </a>
                      </div>
                    </div>
                    <div class="btn-group me-2 mb-2 mb-sm-0">
                      <button
                        type="button"
                        class="btn btn-primary waves-light waves-effect dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-tag"></i>{" "}
                        <i class="mdi mdi-chevron-down ms-1"></i>
                      </button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#">
                          Updates
                        </a>
                        <a class="dropdown-item" href="#">
                          Social
                        </a>
                        <a class="dropdown-item" href="#">
                          Team Manage
                        </a>
                      </div>
                    </div>

                    <div class="btn-group me-2 mb-2 mb-sm-0">
                      <button
                        type="button"
                        class="btn btn-primary waves-light waves-effect dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        More <i class="mdi mdi-dots-vertical ms-2"></i>
                      </button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#">
                          Mark as Unread
                        </a>
                        <a class="dropdown-item" href="#">
                          Mark as Important
                        </a>
                        <a class="dropdown-item" href="#">
                          Add to Tasks
                        </a>
                        <a class="dropdown-item" href="#">
                          Add Star
                        </a>
                        <a class="dropdown-item" href="#">
                          Mute
                        </a>
                      </div>
                    </div>
                  </div>
                  <ul class="message-list">
                    <li>
                      <div class="col-mail col-mail-1">
                        <div class="checkbox-wrapper-mail">
                          <input type="checkbox" id="chk19" />
                          <label
                            class="form-label"
                            for="chk19"
                            class="toggle"
                          ></label>
                        </div>
                        <a href="#" class="title">
                          Peter, me (3)
                        </a>
                        <span class="star-toggle far fa-star"></span>
                      </div>
                      <div class="col-mail col-mail-2">
                        <a href="#" class="subject">
                          Hello –{" "}
                          <span class="teaser">
                            Trip home from Colombo has been arranged, then Jenna
                            will come get me from Stockholm. :
                          </span>
                        </a>
                        <div class="date">Mar 6</div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="row">
                  <div class="col-7">Showing 1 - 1 of 1</div>
                  <div class="col-5">
                    <div class="btn-group float-end">
                      <button
                        type="button"
                        class="btn btn-sm btn-success waves-effect"
                      >
                        <i class="fa fa-chevron-left"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-success waves-effect"
                      >
                        <i class="fa fa-chevron-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Email;
