import React, {useState, useEffect} from "react";
import HeaderComp from "../../Components/HeaderComp";
import CategoryList from "../../Components/CategoryList/CategoryList";
import {addProducts, getCJCategoryList} from '../../api/CJApi';
import Papa from 'papaparse';

const CJAddProduct = () => {

  const [catlist, setCatlist] = useState([]);
  const [mainCat, setMainCat] = useState('');
  const [subCat, setSubCat] = useState('');
  const [lowCat, setLowCat] = useState('');
  const [data, setData] = useState('');

  const getCatList = async () => {
    const list = await getCJCategoryList();
    setCatlist(list.categorylist);
  };

  const getSelectedCategories = (main_cat, sub_cat, low_cat) => {
    setMainCat(main_cat);
    setSubCat(sub_cat);
    setLowCat(low_cat);
  }

  useEffect(() => {
    getCatList();
  }, []);

  const [isValidJSON, setIsValidJSON] = useState(false);

  const uploadData = async () => {

    try {
      // Attempt to parse the content as JSON
      const products = JSON.parse(data);
      const productsListver = products.content[0].productList;
      const productsList = productsListver.map(prods => {
        return(
       {
        merci_main_cat: mainCat, 
        merci_sub_cat: subCat,
        merci_low_cat: lowCat,
        merci_spu_id: prods.sku,
        merci_category_id: prods.categoryId,
        merci_prod_name: prods.nameEn,
        merci_mrp: prods.sellPrice,
        merci_prod_img: prods.bigImage
        }
        
        )
      });
      const res = await addProducts(productsList);
      alert(res.message)
      setData('');
    } catch (error) {
    alert('Data is not Valid Json');
    }
  };




  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!file) {
      alert('Please select a file first!');
      return;
    }

    Papa.parse(file, {
      complete: (results) => {
        const data = results.data.map(row => ({
          ...row,
          merci_ishot : false,
          merci_isValuable : false,
          merci_merci_discount: 200,
          merci_us_discount: 200,
        }))
        console.log('Parsed Data: ', data.slice(0,-1));
        sendDataToAPI(data.slice(0,-1));
      },
      header: true, // Assuming the CSV has headers
    });
  };

  const validateRow = (row) => {
    return (
      row.merci_main_cat &&
      row.merci_sub_cat &&
      row.merci_low_cat &&
      row.merci_spu_id &&
      row.merci_prod_name &&
      row.merci_mrp
    );
  };

  
  const sendDataToAPI = async (data) => {
    const res = await addProducts(data);
    console.log('res', res);
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Upload Product" />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Categories</h4>
                  <p className="card-title-desc">Categories List of Doba</p>
                  <CategoryList
                    company="cj"
                    getSelectedCategories={getSelectedCategories}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Upload File</h4>
                <div className="input-group">
                  <input type="file" className="form-control" id="customFile" onChange={e => handleFileChange(e)} />
                </div>
                <button onClick={handleUpload} className="btn btn-primary mt-3">
            Upload
          </button>
                {/* <div style={{ marginTop: "2rem" }}>
                  <button className="btn btn-primary" type="submit" onClick={{handleFileChange}}>
                    Upload Data
                  </button>
                </div> */}
              </div>
            </div>
          </div>

          {/* <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add Data</h4>
                <div className="input-group">
                  <textarea className="form-control" id="customFile" rows="50" onChange={e => setData(e.target.value)} value={data}></textarea>
                </div>
                <div style={{ marginTop: "2rem" }}>
                  <button className="btn btn-primary" type="submit" onClick={uploadData}>
                    Upload Data
                  </button>
                </div>
               
              </div>
            </div>
          </div> */}


        </div>
      </div>
    </div>
  );
};

export default CJAddProduct;
