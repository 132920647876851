import React, { useState } from "react";
import HeaderComp from "../../Components/HeaderComp";
import { passwordUpdate, shopPasswordUpdate } from "../../api/loginUser";

const ChangePassword = () => {
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewtPass] = useState("");
  const [confirmNewPass, setConfirmNewPass] = useState("");
  const [err, setErr] = useState("");

  const handlePassChange = async () => {
    if (newPass === currentPass)
      return alert("Your Current password and New Password should not be same");
    if (newPass !== confirmNewPass)
      return alert("Your new password and confirmation Password are not same");
    if (localStorage.getItem("mercimode") === "admin") {
      const res = await passwordUpdate(
        localStorage.getItem("merciadminuser"),
        currentPass,
        newPass
      );
      if (res.status !== 200) {
        alert(res.message);
      } else {
        localStorage.removeItem("merciadminuser");
        localStorage.removeItem("mercimode");
        window.location.reload();
      }
    } else if (localStorage.getItem("mercimode") === "shop") {
      const res = await shopPasswordUpdate(
        localStorage.getItem("merciadminuser"),
        currentPass,
        newPass
      );
      console.log("res", res);
    }
  };

  const validatePassword = () => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(.{8,})$/;

    if (!passwordRegex.test(newPass)) {
      setErr("Password must have at least 8 characters, one uppercase letter, one number, and one special character like !,@,#,$,%,^,&,*.");
    } else {
        setErr('');
    }

  };
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Change Password" />
          <div className="row">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Change Password</h4>
                <p className="card-title-desc">Password must have at least 8 characters, 
                one uppercase letter, one number, and one special character like !,@,#,$,%,^,&,*.</p>
                <div className="col-12">
                  <div className="row mb-3">
                    <div className="col-sm-6">
                        <label>Current Password</label>
                        <input
                            className="form-control"
                            type="password"
                            placeholder="Current Password"
                            id="example-search-input"
                            value={currentPass}
                            onChange={(e) => setCurrentPass(e.target.value)}
                        />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label>New Password</label>
                      <input
                        className="form-control"
                        type="password"
                        placeholder="New Password"
                        id="example-search-input"
                        value={newPass}
                        onChange={(e) => setNewtPass(e.target.value)}
                        onBlur={validatePassword}
                      />
                      {err && <p style={{color: 'red', fontWeight: '600'}}>{err}</p> }
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6">
                        <label>Confirm New Password</label> 
                        <input
                            className="form-control"
                            type="password"
                            placeholder="Confirm New Password"
                            id="example-search-input"
                            value={confirmNewPass}
                            onChange={(e) => setConfirmNewPass(e.target.value)}
                        />
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={handlePassChange}
                  >
                    Update Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
