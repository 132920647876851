import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getCouponsByMerchantId } from '../../api/couponsApi';
import HeaderComp from '../../Components/HeaderComp';
import { rupee } from '../../constants/priceFormats';

const CouponsList = () => {
    const userData = useSelector((state) => state.user.user);
    const [coupons, setCoupons] =  useState([]);

    const getCoupons = async () => {

      if(userData._id){
      const coup = await getCouponsByMerchantId(userData._id);
      setCoupons(coup);
      }

    }

    useEffect( () => {
      getCoupons();
    },[userData])

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Coupons List" />

            <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Coupons Not Assigned</h4>

                  <div className="table-responsive">
                    <table className="table mb-0" style={{tableLayout: 'fixed'}}>
                      <thead>
                        <tr>
                          <th>Sl No</th>
                          <th>Coupon</th>
                          <th>Value</th>
                          <th>Used</th>
                          <th>Name</th>
                          <th>Number</th>
                        </tr>
                      </thead>
                      <tbody>  
                        {coupons &&
                          coupons.map((shop, index) => {
                            return (
                              <tr key={index}>
                                <td>{index+1}</td>
                                <td>{shop.coupon}</td>
                                <td>{rupee.format(shop.value)}</td>
                                <td>{rupee.format(shop.used)}</td>
                                <td>{shop.assignedTo}</td>
                                <td>{shop.assignedPhone}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default CouponsList