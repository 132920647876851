import React from "react";
import HeaderComp from "../../Components/HeaderComp";

const Banking = () => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Banking" />
          <div className="row" style={{ margin: "2rem" }}>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Credited Amount
                      </p>
                      <h4 className="mb-0">12345</h4>
                    </div>
                    <div className="text-primary ms-auto">
                      <i className=" fas fa-rupee-sign font-size-24"></i>
                    </div>
                  </div>
                </div>

                <div className="card-body border-top py-3">
                  <div className="text-truncate">
                    <span className="badge badge-soft-success font-size-11">
                      <i className="mdi mdi-menu-up"> </i> 2.4%{" "}
                    </span>
                    <span className="text-muted ms-2">
                      From previous period
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Debitted Amount
                      </p>
                      <h4 className="mb-0">15678</h4>
                    </div>
                    <div className="text-primary ms-auto">
                      <i className=" fas fa-rupee-sign font-size-24"></i>
                    </div>
                  </div>
                </div>
                <div className="card-body border-top py-3">
                  <div className="text-truncate">
                    <span className="badge badge-soft-success font-size-11">
                      <i className="mdi mdi-menu-up"> </i> 2.4%{" "}
                    </span>
                    <span className="text-muted ms-2">
                      From previous period
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Total Earnings
                      </p>
                      <h4 className="mb-0"> 15.4</h4>
                    </div>
                    <div className="text-primary ms-auto">
                      <i className=" fas fa-rupee-sign font-size-24"></i>
                    </div>
                  </div>
                </div>
                <div className="card-body border-top py-3">
                  <div className="text-truncate">
                    <span className="badge badge-soft-success font-size-11">
                      <i className="mdi mdi-menu-up"> </i> 2.4%{" "}
                    </span>
                    <span className="text-muted ms-2">
                      From previous period
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Transfer Money With Bank Account</h4>
                  <p>Transfer Amount to Any Bank Account With Account Number and IFSC Code</p>
                  <div className="row mb-3">
                    <label
                      for="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Bank Account Number
                    </label>
                    <div className="col-sm-10">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Confirm Bank Account Number"
                        id="example-text-input"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      for="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                     Confirm Bank Account Number
                    </label>
                    <div className="col-sm-10">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Confirm Bank Account Number"
                        id="example-text-input"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      for="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                     IFSC Code
                    </label>
                    <div className="col-sm-10">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="IFSC Code"
                        id="example-text-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Transfer Money With UPI </h4>
                  <p>Transfer Amount to Any Bank Account With UPI</p>
                  <div className="row mb-3">
                    <label
                      for="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      UPI ID
                    </label>
                    <div className="col-sm-10">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="UPI ID"
                        id="example-text-input"
                      />
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banking;
