import React from "react";

const CategoryListAll = ({
  onMainSelect,
  mainlist,
  onSubSelect,
  sublist,
  onLowSelect,
  lowlist,
  getSelectedCategories,
}) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Categories</h4>
            <p className="card-title-desc">Categories List </p>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-0">
                  <label className="form-label">Select Main Category</label>
                  <select
                    className="form-control select2-search-disable"
                    onChange={(e) => onMainSelect(e)}
                  >
                    <option selected="">Select Main category</option>
                    {mainlist?.map((main, index) => (
                      <option key={index} value={main}>
                        {main}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="mb-0">
                  <label className="form-label">Select Sub Category</label>
                  <select
                    className="form-control select2-search-disable"
                    onChange={(e) => onSubSelect(e)}
                  >
                    <option selected="">Select Sub category</option>
                    {sublist.map((main, index) => (
                      <option key={index} value={main}>
                        {main}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-0">
                  <label className="form-label">Select Low Category</label>
                  <select
                    className="form-control select2-search-disable"
                    onChange={(e) => onLowSelect(e)}
                  >
                    <option selected="">Select Low category</option>
                    {lowlist.map((main, index) => (
                      <option key={index} value={main}>
                        {main}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "1rem" }}>
              <button
                className="btn btn-primary"
                onClick={getSelectedCategories}
                type="submit"
              >
                Get Products
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryListAll;
