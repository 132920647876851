import React, { useState, useEffect } from "react";
// import { getCategoryList } from "../../api/MeeshoApi";
// import { getDobaCategoryList } from "../../api/DobaApi";
import { getCJCategoryList } from "../../api/CJApi";
// import { getDeodapCategoryList } from "../../api/DeodapApi";

const CategoryList = ({getSelectedCategories, company = '' }) => {

  const [catlist, setCatlist] = useState([]);
  const [mainlist, setmainlist] = useState([]);
  const [mainselect, setmainselect] = useState([]);
  const [sublist, setsublist] = useState([]);
  const [subselect, setsubselect] = useState([]);
  const [lowlist, setlowlist] = useState([]);
  const [lowselect, setlowselect] = useState([]);
  const [changed, setChanged] = useState(false);

  const getCatList = async () => {
    let list;
    if(company === 'meesho'){
      // list = await getCategoryList();
    } else if(company === 'doba') {
      // list = await getDobaCategoryList();
    }  else if(company === 'cj') {
      list = await getCJCategoryList();
    } else if(company === 'deodap') {
      // list = await getDeodapCategoryList();
    }
    setCatlist(list.categorylist);

    const mainlistfilter = [
      ...new Set(list?.categorylist?.map((data) => data.main_cat)),
    ];
    setmainlist(mainlistfilter);
  };
  const onMainSelect = (e) => {
    const sublist = catlist.filter((data) => data.main_cat === e.target.value);
    const sublistfilter = [...new Set(sublist?.map((data) => data.sub_cat))];
    setmainselect(e.target.value);
    setsublist(sublistfilter);
   //  getSelectedCategories(e.target.value, '', '');
  };

  const onSubSelect = (e) => {
    const lowlist = catlist.filter(
      (data) => data.main_cat === mainselect && data.sub_cat === e.target.value
    );
    const lowlistfilter = [...new Set(lowlist?.map((data) => data.low_cat))];
    setsubselect(e.target.value);
    setlowlist(lowlistfilter);
   //  getSelectedCategories(mainselect, e.target.value, '');
    
  };

  const onLowSelect = (e) => {
    setlowselect(e.target.value);
    getSelectedCategories(mainselect, subselect, e.target.value);
  }

  const getMainlist = () => {
    const mainlistfilter = [...new Set(catlist?.map((data) => data.main_cat))];
    setmainlist(mainlistfilter);
    setChanged(!changed);
  };
  useEffect(() => {
    getCatList();
    getMainlist();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-0">
            <label className="form-label">Select Main Category</label>
            <select
              className="form-control select2-search-disable"
              onChange={(e) => onMainSelect(e)}
            >
              <option selected="">Select Main category</option>
              {mainlist.map((main, index) => (
                <option key={index} value={main}>
                  {main}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="mb-0">
            <label className="form-label">Select Sub Category</label>
            <select
              className="form-control select2-search-disable"
              onChange={(e) => onSubSelect(e)}
            >
              <option selected="">Select Sub category</option>
              {sublist.map((main, index) => (
                <option key={index} value={main}>
                  {main}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-0">
            <label className="form-label">Select Low Category</label>
            <select
              className="form-control select2-search-disable"
              onChange={(e) => onLowSelect(e)}
            >
              <option selected="">Select Low category</option>
              {lowlist.map((main, index) => (
                <option key={index} value={main}>
                  {main}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryList;
