import { useState, useEffect } from "react";
import "./App.css";
import Routers from "./Routers";
import Header from "./Components/Header/Header";
import SideMenu from "./Components/SideMenu/SideMenu";
import Footer from "./Components/Footer";
import LoginPage from "./Pages/Authentication/LoginPage";
import { getUserDetails } from "./features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";

const App = () => {

  const dispatch = useDispatch();
  const [isUserLogged, setIsUserLogged] = useState(false);
  const userData = useSelector(state => state.user.user);

  useEffect(() => {
    if(localStorage.getItem('merciadminuser')){
      setIsUserLogged(true)
      dispatch(getUserDetails(localStorage.getItem('merciadminuser')))
    }
  },[dispatch])

  return (
    <div data-sidebar="dark">
      <div id="layout-wrapper">
        {!isUserLogged ? (
          <LoginPage />
        ) : (
          <>
            <Header userData={userData}/>
            <SideMenu />
            <Routers />
            <Footer />
          </>
        )}
      </div>
    </div>
  );
};

export default App;
