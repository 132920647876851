import React, { useEffect, useState } from 'react'
import HeaderComp from '../../Components/HeaderComp'
import { useSelector } from 'react-redux';
import { getTransactionList } from '../../api/RooftopApi';

const GreenTransactions = () => {
  const userData = useSelector((state) => state.user.user);
  const [translist, setTranslist] = useState([]) 

  const getTranslist = async () => {
    if(userData._id) {
      const list = await getTransactionList(userData._id);
      setTranslist(list.data);
    }
  }

  useEffect(() => { getTranslist()}, [userData]);
  return (
    <div className="main-content">
    <div className="page-content">
      <div className="container-fluid">
        <HeaderComp title="Transactions" />
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Transactions List</h4>

                <div className="table-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Sl No</th>
                        <th>Order Id</th>
                        <th>Coupon</th>
                        <th>Value Used</th>
                      </tr>
                    </thead>
                    <tbody>
                      {translist &&
                        translist.map((shop, index) => {
                          return (
                            <tr key={index}>
                              <td>{index+1}</td>
                              <td>{shop.ORID}</td>
                              <td>{shop.coupon}</td>
                              <td>{shop.valueUsed}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default GreenTransactions