import axios from 'axios';
import { URL } from '../constants/constants';

export const checkValidUser = async userId => {
    const idVal = { id: userId }; 
  const validCheck = await axios.post(`${URL}/useridCheck/`, idVal ).then( res => res.data);
  return validCheck;
}


export const checkPhoneNumber = async phone => {
  const idVal = { phone: phone }; 
const validCheck = await axios.post(`${URL}/phone/`, idVal ).then( res => res.data);
return validCheck;
}

export const checkPancard = async pancard => {
  const idVal = { pancard: pancard }; 
const validCheck = await axios.post(`${URL}/pancard/`, idVal ).then( res => res.data);
return validCheck;
}

export const createUser = async userDetails => {
const validCheck = await axios.post(`${URL}/`, userDetails ).then( res => res.data);
return validCheck;
}

export const getUsersList = async refer => {
  const idVal = { refer: refer }; 
  const validCheck = await axios.post(`${URL}/referList/`, idVal ).then( res => res.data);
  return validCheck;
}

export const getUser = async id => {
  const userDetails = await axios.get(`${URL}/${id}`).then( res => res.data);
  return userDetails;
}

export const getShopUser = async id => {
  const userDetails = await axios.get(`${URL}/getshopuser/${id}`).then( res => res.data);
  return userDetails;
}

export const deleteUsers = async id => {
  const userDetails = await axios.delete(`${URL}/${id}`).then( res => res.data);
  return userDetails;
}

export const fetchHierarchy = async (refer, level) => {
  const userDetails = await axios.get(`${URL}/hierarchy/${refer}/${level}`).then( res => res.data);
  return userDetails;
}



