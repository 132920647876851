import React, {useEffect, useState} from 'react'
import { getContacts, getStates } from '../../api/bloodData';
import HeaderComp from '../../Components/HeaderComp';
import StatesList from '../../Components/CategoryList/StatesList';

const Contacts = () => {

     const [contactsSel, setContactsSel] = useState([]);

    const getSelectedCategories =  async (stateVal, districtVal, cityVal) => {
       const contactsList =  await getContacts(stateVal, districtVal,cityVal);
       setContactsSel(contactsList.contactsList);
    }

  return (
    <div className="main-content">
    <div className="page-content">
      <div className="container-fluid">
        <HeaderComp title="Contacts" />

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Contacts</h4>
                <p className="card-title-desc">Select State, District and City</p>
                <StatesList getSelectedCategories={getSelectedCategories}/>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">All Categories</h4>
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>State</th>
                          <th>District</th>
                          <th>City</th>
                          <th>Name</th>
                          <th>Phone Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contactsSel?.map((cat, index) => {
                          return(
                            <tr key={index}>
                          <th scope="row">{index+1}</th>
                          <td>{cat.State}</td>
                          <td>{cat.District}</td>
                          <td>{cat.City}</td>
                          <td>{cat.Name}</td>
                          <td>{cat.Phone}</td>
                        </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  )
}

export default Contacts