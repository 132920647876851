import React from "react";

const SortProducts = ({ totalProducts, active, updateProductsList }) => {
  return (
    <ul className="list-inline my-3 ecommerce-sortby-list">
      <li className="list-inline-item">
        <span className="fw-medium font-family-secondary">Sort by:</span>
      </li>
      <li
        className={
          active === 0 ? "list-inline-item active" : "list-inline-item"
        }
        style={{ cursor: "pointer" }}
      >
        <a onClick={() => updateProductsList(0)}>
          Total Products ({totalProducts}){" "}
        </a>
      </li>
      <li
        className={
          active === 1 ? `list-inline-item active` : `list-inline-item`
        }
        style={{ cursor: "pointer" }}
      >
        <a onClick={() => updateProductsList(1)}>Hot Products</a>
      </li>
      <li
        className={
          active === 2 ? `list-inline-item active` : `list-inline-item`
        }
        style={{ cursor: "pointer" }}
      >
        <a onClick={() => updateProductsList(2)}>Valuable Products</a>
      </li>
    </ul>
  );
};

export default SortProducts;
