import React from "react";
import HeaderComp from "../../Components/HeaderComp";
import VerticalTab from "../../Components/VerticalTabs/VerticalTab";

const Bills = () => {
  const mobileRechargeTab = () => {
    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <div className="mb-0">
              <label className="col-sm-2 col-form-label">Enter Phone Number</label>
              <input
                className="form-control"
                type="text"
                placeholder="Phone Number"
                id="example-text-input"
              />
            </div>
            <div className="mb-0">
              <label className="form-label">Select Operator</label>
              <select className="form-control select2-search-disable">
                <option value="Select Operator">Select Operator</option>
                <option value="Airtel">Airtel</option>
                <option value="Aircel">Aircel</option>
                <option value="BSNL">BSNL</option>
                <option value="Tata Docomo">Tata Docomo</option>
                <option value="Reliance GSM">Reliance GSM</option>
                <option value="Reliance CDMA">Reliance CDMA</option>
                <option value="Telenor">Telenor</option>
                <option value="Jio">Jio</option>
                <option value="Vodafone">Vodafone</option>
                <option value="Idea">Idea</option>
                <option value="MTS">MTS</option>
              </select>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="mb-0">
              <label className="form-label">Select Circle</label>
              <select className="form-control select2-search-disable">
                <option value="Select Circle">Select Circle</option>
                <option value="Chennai">Chennai</option>
                <option value="Delhi NCR">Delhi NCR</option>
                <option value="Kolkata">Kolkata</option>
                <option value="Mumbai">Mumbai</option>
                <option value="Maharashtra & Goa">Maharashtra & Goa</option>
                <option value="Assam">Assam</option>
                <option value="Bihar & Jharkhand">Bihar & Jharkhand</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jummu & Kashmir">Jummu & Kashmir</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="MP & Chattisgarh">MP & Chattisgarh</option>
                <option value="North East">North East</option>
                <option value="Orissa">Orissa</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Tamilnadu">Tamilnadu</option>
                <option value="UP East">UP East</option>
                <option value="UP West & Utterkhand">
                  UP West & Utterkhand
                </option>
                <option value="West Bengal">West Bengal</option>
              </select>
            </div>
          </div>
        </div>
      </>
    );
  };
  const tabs = [
    {
      title: "Mobile",
      content: mobileRechargeTab(),
    },
    {
      title: "DTH",
      content: <p>This is the content of Tab 2</p>,
    },
    {
      title: "Data Card",
      content: <p>This is the content of Tab 2</p>,
    },
    {
      title: "Electricity",
      content: <p>This is the content of Tab 2</p>,
    },
    {
      title: "Land Line",
      content: <p>This is the content of Tab 2</p>,
    },
    {
      title: "Broad Band",
      content: <p>This is the content of Tab 2</p>,
    },
    {
      title: "Gas",
      content: <p>This is the content of Tab 2</p>,
    },
    {
      title: "Water",
      content: <p>This is the content of Tab 2</p>,
    },
    {
      title: "Metro",
      content: <p>This is the content of Tab 2</p>,
    },
    // Add more tabs as needed
  ];
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Pay Bills" />

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Pay Bills</h4>
                  <p className="card-title-desc">
                    Pay your Utility Bills, Recharge Your Phone and Book Tickets
                  </p>
                  <VerticalTab tabs={tabs} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Pay Bills</h4>
                  <p className="card-title-desc">
                  Pay your Utility Bills, Recharge Your Phone and Book Tickets
                  </p>

                  {/* Nav tabs */}
                  <ul className="nav nav-pills nav-justified" role="tablist">
                    <li className="nav-item waves-effect waves-light">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#home-1"
                        role="tab"
                      >
                        <span className="d-block d-sm" style={{fontSize: "3rem"}}>
                          <i className="fas fa-mobile-alt"></i>
                        </span>
                        <span className="d-none d-sm-block">Mobile</span>
                      </a>
                    </li>
                    <li className="nav-item waves-effect waves-light">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#profile-1"
                        role="tab"
                      >
                        <span className="d-block d-sm" style={{fontSize: "3rem"}}>
                          <i className="fas fa-satellite-dish"></i>
                        </span>
                        <span className="d-none d-sm-block">DTH</span>
                      </a>
                    </li>
                    <li className="nav-item waves-effect waves-light">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#messages-1"
                        role="tab"
                      >
                        <span className="d-block d-sm" style={{fontSize: "3rem"}}>
                        <i className="fas fa-solid fa-bolt" ></i>
                        </span>
                        <span className="d-none d-sm-block">Electricity</span>
                      </a>
                    </li>
                    <li className="nav-item waves-effect waves-light">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#settings-1"
                        role="tab"
                      >
                        <span className="d-block d-sm" style={{fontSize: "3rem"}}>
                          <i className="fas fa-phone"></i>
                        </span>
                        <span className="d-none d-sm-block">Land Line</span>
                      </a>
                    </li>
                    <li className="nav-item waves-effect waves-light">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#settings-1"
                        role="tab"
                      >
                        <span className="d-block d-sm" style={{fontSize: "3rem"}}>
                          <i className="ri-delete-bin-7-fill"></i>
                        </span>
                        <span className="d-none d-sm-block">Gas</span>
                      </a>
                    </li>
                    <li className="nav-item waves-effect waves-light">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#settings-1"
                        role="tab"
                      >
                        <span className="d-block d-sm" style={{fontSize: "3rem"}}>
                        <i className="fas fa-solid fa-wifi"></i>
                        </span>
                        <span className="d-none d-sm-block">Broad Band</span>
                      </a>
                    </li>
                    <li className="nav-item waves-effect waves-light">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#settings-1"
                        role="tab"
                      >
                        <span className="d-block d-sm" style={{fontSize: '3rem'}}>
                          <i className="ri-contrast-drop-2-line"></i>
                        </span>
                        <span className="d-none d-sm-block">Water Bill</span>
                      </a>
                    </li>
                    <li className="nav-item waves-effect waves-light">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#settings-1"
                        role="tab"
                      >
                        <span className="d-block d-sm" style={{fontSize: '3rem'}}>
                          <i className="ri-train-fill"></i>
                        </span>
                        <span className="d-none d-sm-block">Metro</span>
                      </a>
                    </li>
                  </ul>

                  {/* Tab panes */}
                  <div className="tab-content p-3 text-muted">
                    <div
                      className="tab-pane active"
                      id="home-1"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-md-12">
                            <div>
                                <h5 className="font-size-14 mb-4">Form Radios</h5>
                                <div className="form-check mb-3">
                                    <input className="form-check-input" type="radio" name="formRadios"
                                        id="formRadios1" checked/>
                                    <label className="form-check-label" for="formRadios1">
                                        PrePaid Connection
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="formRadios"
                                        id="formRadios2"/>
                                    <label className="form-check-label" for="formRadios2">
                                        Post Paid Connection
                                    </label>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="profile-1" role="tabpanel">
                      <p className="mb-0">
                        Food truck fixie locavore, accusamus mcsweeney's marfa
                        nulla single-origin coffee squid. Exercitation +1 labore
                        velit, blog sartorial PBR leggings next level wes
                        anderson artisan four loko farm-to-table craft beer
                        twee. Qui photo booth letterpress, commodo enim craft
                        beer mlkshk aliquip jean shorts ullamco ad vinyl cillum
                        PBR. Homo nostrud organic, assumenda labore aesthetic
                        magna 8-bit.
                      </p>
                    </div>
                    <div className="tab-pane" id="messages-1" role="tabpanel">
                      <p className="mb-0">
                        Etsy mixtape wayfarers, ethical wes anderson tofu before
                        they sold out mcsweeney's organic lomo retro fanny pack
                        lo-fi farm-to-table readymade. Messenger bag gentrify
                        pitchfork tattooed craft beer, iphone skateboard
                        locavore carles etsy salvia banksy hoodie helvetica. DIY
                        synth PBR banksy irony. Leggings gentrify squid 8-bit
                        cred pitchfork. Williamsburg banh mi whatever
                        gluten-free.
                      </p>
                    </div>
                    <div className="tab-pane" id="settings-1" role="tabpanel">
                      <p className="mb-0">
                        Trust fund seitan letterpress, keytar raw denim keffiyeh
                        etsy art party before they sold out master cleanse
                        gluten-free squid scenester freegan cosby sweater. Fanny
                        pack portland seitan DIY, art party locavore wolf cliche
                        high life echo park Austin. Cred vinyl keffiyeh DIY
                        salvia PBR, banh mi before they sold out farm-to-table.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bills;
