import axios from 'axios';
import { BLOODURL } from '../constants/constants';


export const getStates = async () => {
    const list = await axios.get(`${BLOODURL}/states`).then( res => res.data);
    return list;
}

export const getContacts = async (state, district, city)  => {  
    const prodselect = {state, district, city}
    const catelist = await axios.post(`${BLOODURL}`, prodselect).then( res => res.data);
    return catelist;
}