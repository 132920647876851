import React, { useState } from "react";
import HeaderComp from "../../Components/HeaderComp";
import { getRooftopShop, getTransactionList } from "../../api/RooftopApi";

const RooftopShopdetails = () => {
  const [userName, setUserName] = useState("");
  const [shopdetails, setShopdetails] = useState({});
  const [transList, setTransList] = useState([]);

  const getShopDetails = async () => {
    const res = await getRooftopShop(userName);
    console.log(res);
    setShopdetails(res);
    const list = await getTransactionList(res._id);
    setTransList(list.data);
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Find User" />
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Find Shop </h4>
                  <div className="row mb-2">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Find Rooftop Shop
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Refer ID, Email or Phone Number"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        id="example-text-input"
                      />
                    </div>
                    <div className="col-sm-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={getShopDetails}
                      >
                        Get Shop Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title" style={{ marginBottom: "2rem" }}>
                    Shop Details
                  </h4>
                  {Object.entries(shopdetails).length !== 0 && (
                    <div className="table-responsive">
                      <table
                        className="table table-bordered mb-0"
                        style={{ tableLayout: "fixed" }}
                      >
                        <tbody>
                          <tr>
                            <td>Shop Name</td>
                            <td>{shopdetails?.merci_shop_name}</td>
                          </tr>
                          <tr>
                            <td>User Name</td>
                            <td>{shopdetails?.merci_user_name}</td>
                          </tr>
                          <tr>
                            <td>Owner Name</td>
                            <td>{shopdetails?.merci_full_name}</td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>{shopdetails?.merci_email}</td>
                          </tr>
                          <tr>
                            <td>Phone</td>
                            <td>{shopdetails?.merci_phone}</td>
                          </tr>
                          <tr>
                            <td>Green Money</td>
                            <td>{shopdetails?.merci_green_money}</td>
                          </tr>
                          <tr>
                            <td>Payment Status</td>
                            <td>
                              {shopdetails?.merci_isPayment
                                ? "Done"
                                : "Not Done"}
                            </td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <td>{`${shopdetails?.merci_street_one},${shopdetails?.merci_street_two},
                        ${shopdetails?.merci_street_village},${shopdetails?.merci_mandal},${shopdetails?.merci_district},
                        ${shopdetails?.merci_state},${shopdetails?.merci_pincode}`}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title" style={{ marginBottom: "2rem" }}>
                    Green Money Transactions
                  </h4>
                  <div className="table-responsive">
                    <table
                      className="table table-bordered mb-0"
                      style={{ tableLayout: "fixed" }}
                    >
                      <tbody>
                        <tr>
                          <td>Green Money</td>
                          <td>{shopdetails?.merci_total}</td>
                        </tr>
                        <tr>
                          <td>Balance</td>
                          <td>{shopdetails?.merci_green_money}</td>
                        </tr>
                        <tr>
                          <td>Coupons Issued</td>
                          <td>{shopdetails?.merci_coupons_generated}</td>
                        </tr>
                        <tr>
                          <td>Coupons Used</td>
                          <td>{shopdetails?.merci_coupons_used}</td>
                        </tr>
                        <tr>
                          <td>Coupons Balance</td>
                          <td>{shopdetails?.merci_coupons_balance}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title" style={{ marginBottom: "2rem" }}>
                    Transaction Details
                  </h4>
                  <div className="table-responsive">
                    <table
                      className="table table-bordered mb-0"
                      style={{ tableLayout: "fixed" }}
                    >
                      <thead>
                        <tr>
                          <td>Sl No</td>
                          <td>Order ID</td>
                          <td>Coupon</td>
                          <td>Used</td>
                        </tr>
                      </thead>
                      <tbody>
                        {transList.length !== 0 &&
                          transList.map((lis, ind) => {
                            return (
                              <tr key={ind}>
                                <td>{ind + 1}</td>
                                <td>{lis.ORID}</td>
                                <td>{lis.coupon}</td>
                                <td>{lis.valueUsed}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RooftopShopdetails;
