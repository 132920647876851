import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeaderComp from "../../Components/HeaderComp";
import { rupee } from "../../constants/priceFormats";
import { getMonthlyList, getrooftopProduct } from "../../api/RooftopApi";
import {
    createMonthlyOrder,
  paymentMethod,
  paymentVerfication,
} from "../../api/PaymentApi";
import { longDate } from "../../Utils/DateFormat";

const PurchaseProduct = () => {

  const userData = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [amnt, setAmnt] = useState(0);
  const [shops, setShops] = useState([]);
  const [price, setPrice] = useState(0);
  const [getList, setGetList] = useState([]);

  useEffect(() => {
    getUsers();
    getTranslist();
  }, []);

  const getUsers = async () => {
    const shopsList = await getrooftopProduct();
    setShops(shopsList);
  };

  const getTranslist = async () => {
    if(userData._id){
    const transList = await getMonthlyList(userData._id);
    setGetList(transList.transList);
    }
  };

  const onSelectProduct = (prod) => {
    const price = shops.filter((el) => el._id === prod)[0].merci_price;
    setPrice(price);
  };

  const generateUniqueId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000);
    const uniqueId = `ORID${timestamp}${random}`;
    return uniqueId;
  };

  const handlePayment = async () => {
    const amount = amnt * price;
    const currency = "INR";
    const receipt = "0123456789";
    const res = await paymentMethod({
      amount: parseInt(amount * 100),
      currency,
      receipt,
    });

    var option = {
      key: "",
      amount,
      currency,
      name: "Merciplus Tech",
      description: "Test Transaction",
      image: "/img/merci_logo_sm.png",
      order_id: res.id,
      handler: async function (res) {
        const body = { ...res };
        const validateResponse = await paymentVerfication(body);

        if (validateResponse.status === 200) {
          const futureDate = new Date();
          futureDate.setDate(futureDate.getDate() + 7);

          const purchasedData = {
            ORID: generateUniqueId(),
            userId: userData._id,
            orderID: validateResponse.orderId,
            paymentID: validateResponse.paymentId,
            total: amount,
            refer: userData.merci_refer,
            purchasedDate: new Date(),
          };
          await createMonthlyOrder(purchasedData);
          alert("Your order is placed. Thankyou for partnering with us");
          navigate("/");
        } else {
          alert(validateResponse.msg);
        }
      },
      notes: {
        address: "Merciplus Tech PVT LTD",
      },
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new window.Razorpay(option);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Purchase Product" />
          <div className="row" style={{ marginTop: "2rem" }}>
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Purchase Product</h4>
                  <p className="card-title-desc">
                    Please select a Product for monthly purchase
                  </p>

                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <label className="form-label" htmlFor="inputEmail4">
                        Select Product
                      </label>
                      <select
                        type="text"
                        className="form-control"
                        onChange={(e) => onSelectProduct(e.target.value)}
                      >
                        <option value="0">Select Product</option>
                        {shops.map((shop, index) => (
                          <option key={index} value={shop._id}>
                            {shop.merci_title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label" htmlFor="inputEmail4">
                        Quantity
                      </label>
                      <div className="col-sm-6 input-group">
                        <div className="input-group-prepend">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={() =>
                              setAmnt((prev) => Math.max(0, prev - 1))
                            } // Ensure it doesn't go below 0
                          >
                            -
                          </button>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Amount to add for Coupon"
                          value={amnt}
                          onChange={(e) => setAmnt(Number(e.target.value))}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={() => setAmnt((prev) => prev + 1)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>Amount:- {rupee.format(amnt * price)}</p>
                  </div>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={handlePayment}
                      type="submit"
                    >
                      Purchase
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Monthly Purchase List</h4>

                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getList &&
                          getList.map((trans, index) => {
                            return (
                              <tr key={index}>
                                <td>{longDate(trans.createdAt)}</td>
                                <td>{trans.commissionL1 + trans.commissionL2 + trans.commissionL3 + trans.commissionL4 }</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PurchaseProduct;
