import React from 'react'
import HeaderComp from '../../Components/HeaderComp'

const FindUser = () => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Find User" />
          
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Find Member </h4>
                  <p>Find Member with Refer ID, Email or Phone Number </p>
                  <div className="row mb-3">
                    <label
                      for="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Member
                    </label>
                    <div className="col-sm-10">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Refer ID, Email or Phone Number"
                        id="example-text-input"
                      />
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FindUser