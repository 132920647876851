import React, { useState } from "react";
import HeaderComp from "../../Components/HeaderComp";
import { getProductBySKU } from "../../api/CJApi";

const CJSearchProduct = () => {
  const [skucode, setSkucode] = useState("");
  const [product, setProduct] = useState({});

  const getProduct = async () => {
    const products = await getProductBySKU(skucode);
    setProduct(products.productslist[0]);
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Search Product" />
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Search Product</h4>
                  <p className="card-title-desc">
                    Please Enter SKU of the Product to get Details
                  </p>

                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Enter SKU"
                        id="example-search-input"
                        onChange={(e) => setSkucode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={getProduct}
                      type="submit"
                    >
                      Get Product
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {product && Object.keys(product).length !== 0 && (
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xl-5">
                        <div className="product-detail">
                          <div className="row">
                            <div className="col-3">
                              {/* <div
                                className="nav flex-column nav-pills"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                              >
                                {product.merci_prod_img.map((imgsrc, index) => {
                                  return (

                                    <a
                                  className="nav-link active"
                                  id="product-1-tab"
                                  data-bs-toggle="pill"
                                  href="#product-1"
                                  role="tab"
                                >
                                  <img
                                    src={imgsrc.url}
                                    alt="img-1"
                                    className="img-fluid mx-auto d-block tab-img rounded"
                                  />
                                </a>
                                  )
                                })}
                                
                              </div> */}
                            </div>
                            <div className="col-md-8 col-9">
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="product-1"
                                  role="tabpanel"
                                >
                                  <div className="product-img">
                                    <img
                                      src={product.merci_prod_img}
                                      alt="img-1"
                                      className="img-fluid mx-auto d-block"
                                      data-zoom="assets/images/product/img-1.png"
                                    />
                                  </div>
                                </div>

                              </div>
                             
                            </div>
                          </div>
                        </div>
                        /
                      </div>
                      <div className="col-xl-7">
                        <div className="mt-4 mt-xl-3">
                          <a href="#" className="text-primary">
                            {product.merci_low_cat}
                          </a>
                          <h5 className="mt-1 mb-3">
                          {product.merci_prod_name}
                          </h5>

                          <div className="d-inline-flex">
                            <div className="text-muted me-3">
                              <span className="mdi mdi-star text-warning"></span>
                              <span className="mdi mdi-star text-warning"></span>
                              <span className="mdi mdi-star text-warning"></span>
                              <span className="mdi mdi-star text-warning"></span>
                              <span className="mdi mdi-star"></span>
                            </div>
                            <div className="text-muted">( 132 )</div>
                          </div>

                          <h5 className="mt-2">
                            <del className="text-muted me-2">$ {product.merci_mrp}</del>$ {product.merci_srp}{" "}
                            <span className="text-danger font-size-12 ms-2">
                              25 % Off
                            </span>
                          </h5>
                          <p className="mt-3">
                            To achieve this, it would be necessary to have
                            uniform pronunciation
                          </p>
                          <hr className="my-4" />

                          
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <h5 className="font-size-14 mb-3">
                        Product description:{" "}
                      </h5>
                      <div dangerouslySetInnerHTML={{__html: product.merci_des }}></div>
                    </div>

                    <div className="mt-4">
                      <h5 className="font-size-14">Reviews : </h5>
                      <div className="d-inline-flex mb-3">
                        <div className="text-muted me-3">
                          <span className="mdi mdi-star text-warning"></span>
                          <span className="mdi mdi-star text-warning"></span>
                          <span className="mdi mdi-star text-warning"></span>
                          <span className="mdi mdi-star text-warning"></span>
                          <span className="mdi mdi-star"></span>
                        </div>
                        <div className="text-muted">( 132 customer Review)</div>
                      </div>
                      <div className="border p-4 rounded">
                        <div className="d-flex border-bottom pb-3">
                          <div className="flex-1">
                            <p className="text-muted mb-2">
                              To an English person, it will seem like simplified
                              English, as a skeptical Cambridge
                            </p>
                            <h5 className="font-size-15 mb-3">James</h5>

                            <ul className="list-inline product-review-link mb-0">
                              <li className="list-inline-item">
                                <a href="#">
                                  <i className="mdi mdi-thumb-up align-middle me-1"></i>{" "}
                                  Like
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a href="#">
                                  <i className="mdi mdi-message-text align-middle me-1"></i>{" "}
                                  Comment
                                </a>
                              </li>
                            </ul>
                          </div>
                          <p className="float-sm-end font-size-12">
                            11 Feb, 2020
                          </p>
                        </div>
                        <div className="d-flex border-bottom py-3">
                          <div className="flex-1">
                            <p className="text-muted mb-2">
                              Everyone realizes why a new common language would
                              be desirable
                            </p>
                            <h5 className="font-size-15 mb-3">David</h5>

                            <ul className="list-inline product-review-link mb-0">
                              <li className="list-inline-item">
                                <a href="#">
                                  <i className="mdi mdi-thumb-up align-middle me-1"></i>{" "}
                                  Like
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a href="#">
                                  <i className="mdi mdi-message-text align-middle me-1"></i>{" "}
                                  Comment
                                </a>
                              </li>
                            </ul>
                          </div>
                          <p className="float-sm-end font-size-12">
                            22 Jan, 2020
                          </p>
                        </div>
                        <div className="d-flex py-3">
                          <div className="flex-1">
                            <p className="text-muted mb-2">
                              If several languages coalesce, the grammar of the
                              resulting{" "}
                            </p>
                            <h5 className="font-size-15 mb-3">Scott</h5>

                            <ul className="list-inline product-review-link mb-0">
                              <li className="list-inline-item">
                                <a href="#">
                                  <i className="mdi mdi-thumb-up align-middle me-1"></i>{" "}
                                  Like
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a href="#">
                                  <i className="mdi mdi-message-text align-middle me-1"></i>{" "}
                                  Comment
                                </a>
                              </li>
                            </ul>
                          </div>
                          <p className="float-sm-end font-size-12">
                            04 Jan, 2020
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CJSearchProduct;
