import React, { useEffect, useState } from "react";
import HeaderComp from "../../Components/HeaderComp";
import { getOrder, updateOrder } from "../../api/EmartApi";
import { rupee } from "../../constants/priceFormats";
import Popup from "../../Components/Popup/Popup";
import "./Emart.css";

const EmartOrders = () => {
  const [orders, setOrders] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [openData, setOpenData] = useState({});

  const closePopup = () => {
    setIsOpen(false);
  };

  const statusValue = [
    "Order Placed",
    "Order Shipped",
    "Out For Delivery",
    "Delivered",
    "Cancelled",
    "Return Started",
    "Refund Initiated",
    "Refund Completed",
  ];

  // const getContactRequest = async () => {
  //   const data = await getContact();
  //   // console.log(data.contactList);
  //   setContact(data.contactList);
  // };

  const handleStatusChange = async (orid, spuid) => {
    const res = await updateOrder(orid, spuid);
    setIsOpen(false);
    getOrders();
  };

  const handlePopup = (data) => {
    setIsOpen(true);
    setOpenData(data);
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const getOrders = async () => {
    const orders = await getOrder();
    setOrders(orders.data?.reverse());
  };

  useEffect(() => {
    getOrders();
  }, []);
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Orders List" />

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Orders List</h4>
                  <p>Orders List and processing </p>
                  {/* <div className="row mb-3">
                    <label
                      for="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Member
                    </label>
                    <div className="col-sm-10">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Refer ID, Email or Phone Number"
                        id="example-text-input"
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>ORID</th>
                          <th>Order ID</th>
                          <th>Payment ID</th>
                          <th>Purchased Date</th>
                          <th>Total Amount</th>
                          {/* <th>Change Status</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {orders?.map((letter, ind) => {
                          return (
                            <tr>
                              <td
                                className="tdhead"
                                onClick={() => handlePopup(letter)}
                              >
                                {letter.ORID}
                              </td>
                              <td>{letter.orderID}</td>
                              <td>{letter.paymentID}</td>
                              <td>{formatDate(letter.purchasedDate)}</td>
                              <td>{rupee.format(letter.total)}</td>
                              {/* <td>
                                <select onChange={(e)=> handleStatusChange(e.target.value)}>
                                  <option value="1"> Order Placed</option>
                                  <option value="2"> Order Shipped</option>
                                  <option value="3"> Out For Delivery</option>
                                  <option value="4"> Order Delivered</option>
                                  <option value="5"> Cancelled</option>
                                  <option value="6"> Order Returned</option>
                                </select>
                              </td> */}
                              {/* <td>{letter.merci_isOpen ? 'Closed' : 'Open'}</td>
                              <td>{formatDate(letter.createdAt)}</td>
                              <td>{formatDate(letter.updatedAt)}</td>
                              <td></td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isOpen && (
            <Popup isOpen={isOpen} onClose={closePopup}>
              <div style={{ margin: "1rem" }}>
                <h6>{openData.address.merci_name}</h6>
                <h6>{openData.address.merci_mobile}</h6>
                <h6>{`${openData.address.merci_address}, ${openData.address.merci_city}, 
            ${openData.address.merci_state}.${openData.address.merci_pincode} `}</h6>
                <table className="table mb-0" style={{ tableLayout: "fixed" }}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>SPU ID</th>
                      <th>Status</th>
                      <th>Amount</th>
                      <th>Number</th>
                      <th>Change Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {openData.cartData.map((data, ind) => {
                      return (
                        <tr key={ind}>
                          <td>
                            <img
                              src={data.merci_prod_img}
                              alt="product img"
                              width="100px"
                              height="100px"
                            />
                          </td>
                          <td>{data.merci_prod_name}</td>
                          <td>{data.merci_spu_id}</td>
                          <td>{statusValue[data.status - 1]}</td>
                          <td>{data.merci_mrp}</td>
                          <td>{data.merci_count}</td>
                          <td>
                            <button
                              onClick={() =>
                                handleStatusChange(
                                  openData.ORID,
                                  data.merci_spu_id
                                )
                              }
                              disabled={data.status === 4 || data.status === 8}
                            >
                              Update Status
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Popup>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmartOrders;
