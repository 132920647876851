import React,{useState, useEffect} from 'react'
import { getRooftoplist, rooftopChange } from '../../api/RooftopApi';
import HeaderComp from "../../Components/HeaderComp";
import { useSelector } from 'react-redux';

const RooftopList = () => {
  const [shops, setShops] = useState([]);
  const loggedUser = useSelector(state => state.user.user);

  console.log(loggedUser);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    if(loggedUser.merci_refer_id){
    const shopsList = await getRooftoplist(loggedUser.merci_refer_id);
    setShops(shopsList.rooftopShopsList);
    }
  };

  const onCheckChange = async (id, val) => {
    const res = await rooftopChange(id, val);
    alert(res.message);
    window.location.reload();
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="List of Rooftop Shops" />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Shops List</h4>

                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Shop Name</th>
                          <th>User Name</th>
                          <th>Full Name</th>
                          <th>Phone</th>
                          <th>Address</th>
                          <th>Payment</th>
                          <th>Status</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {shops &&
                          shops.map((shop, index) => {
                            return (
                              <tr key={index}>
                                <td>{shop.merci_shop_name}</td>
                                <td>{shop.merci_user_name}</td>
                                <td>{shop.merci_full_name}</td>
                                <td>{shop.merci_phone}</td>
                                <td>{`${shop.merci_mandal}, ${shop.merci_district}, ${shop.merci_state}`}</td>
                                <td> <button className={shop.merci_isPayment? 'btn btn-success waves-effect waves-light'
                                : 'btn btn-danger waves-effect waves-light'} style={{ width: '6rem'}}>
                                  {shop.merci_isPayment ? 'Done' : 'Not Done'}
                                </button>
                                </td>
                                <td>
                                    <i className="fas fa-edit" onClick={() => onCheckChange(shop._id, shop.merci_isActive)} style={{cursor: 'pointer'}}></i>{" "}
                                    {shop.merci_isActive ? 'Active' : 'Not Active'}
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-danger waves-effect waves-light"
                                  >
                                    <i className="ri-close-line align-middle me-2"></i>{" "}
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RooftopList