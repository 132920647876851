import React, { useEffect, useState } from "react";
import HeaderComp from "../../Components/HeaderComp";
import { getRooftoplist } from "../../api/RooftopApi";
import { useSelector } from "react-redux";
import { getCommision } from "../../api/PaymentApi"; 
import { rupee } from "../../constants/priceFormats";

const ShopCommission = () => {
  const userData = useSelector((state) => state.user.user);
  const [commArr, setCommArr] = useState([])

  const getCommissionSum = (dataArray, level, id) => {
    const commissionKey = `commissionL${level.match(/\d+/)[0]}`;
    return dataArray[commissionKey]
  };

  const getCommissionData = async () => {
    if (userData.merci_level) {
      const res = await getCommision(
        userData.merci_level,
        userData.merci_refer_id
      );
      setCommArr(res.data.filter( el => el.paymentType=== 'shop'));
    }
  };

  useEffect(() => {
    getCommissionData();
  }, [userData]);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Rooftop Shop Payments" />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Rooftop Shops Payments List</h4>

                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Shop Name</th>
                          <th>Full Name</th>
                          <th>Phone</th>
                          <th>Payment from shop</th>
                        </tr>
                      </thead>
                      <tbody>
                        {commArr &&
                          commArr.map((shop, index) => {
                            return (
                              <tr key={index}>
                                <td>{shop.merci_shop_details.merci_shop_name}</td>
                                <td>{shop.merci_shop_details.merci_full_name}</td>
                                <td>{shop.merci_shop_details.merci_phone}</td>
                                <td>
                                 {rupee.format(getCommissionSum(shop, userData.merci_level, shop._id))}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopCommission;
