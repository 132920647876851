import React, { useState, useEffect } from "react";
import HeaderComp from "../../Components/HeaderComp";
import CategoryList from "../../Components/CategoryList/CategoryList";
import {getCJCategoryList} from '../../api/CJApi';

const CJCategories = () => {
  const [catlist, setCatlist] = useState([]);

  const getCatList = async () => {
    const list = await getCJCategoryList();
    setCatlist(list.categorylist);
  };

  const getSelectedCategories = (main_cat, sub_cat, low_cat) => {
    
  }

  useEffect(() => {
    getCatList();
  }, []);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="CJ Categories" />

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Categories</h4>
                  <p className="card-title-desc">Categories List of CJ</p>
                  <CategoryList company={'cj'} getSelectedCategories={getSelectedCategories}/>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">All Categories</h4>
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Main Category</th>
                          <th>Sub Category</th>
                          <th>Low Category</th>
                        </tr>
                      </thead>
                      <tbody>
                        {catlist?.map((cat, index) => {
                          return(
                            <tr key={index}>
                          <th scope="row">{index+1}</th>
                          <td>{cat.main_cat}</td>
                          <td>{cat.sub_cat}</td>
                          <td>{cat.low_cat}</td>
                        </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CJCategories;
