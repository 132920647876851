export const longDate = (isoDate) => {
    const date = new Date(isoDate);

// Define the options for formatting the date
const options = { day: 'numeric', month: 'long', year: 'numeric' };

// Get formatted date
let formattedDate = date.toLocaleDateString('en-GB', options);

// Add suffix for the day (e.g., 1st, 2nd, 3rd, etc.)
const day = date.getDate();
const daySuffix = 
  day % 10 === 1 && day !== 11 ? 'st' :
  day % 10 === 2 && day !== 12 ? 'nd' :
  day % 10 === 3 && day !== 13 ? 'rd' : 'th';

 return  formattedDate.replace(date.getDate(), `${day}${daySuffix}`);
}