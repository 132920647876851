import React from "react";
import HeaderComp from "../../Components/HeaderComp";
import { useSelector } from "react-redux";
const Profile = () => {
  const user = useSelector(state => state.user.user);
  return (
    <div className="main-content">
      {console.log(user)}
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Profile" />
          <div className="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Profile</h4>
                  <div class="table-responsive">
                    { (user.merci_level === 'Level 1' ||
                     user.merci_level === 'Level 2') && 
                    <table class="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td>Full Name</td>
                          <td>{`${user.merci_first_name} ${user.merci_middle_name} ${user.merci_last_name}`}</td>
                        </tr>
                        <tr>
                          <td>Phone</td>
                          <td>{user.merci_phone}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>{user.merci_email}</td>
                        </tr>
                        <tr>
                          <td>Address </td>
                          <td>{user.merci_street_one},{user.merci_street_two},
                          {user.merci_village},{user.merci_mandal},{user.merci_district},
                          {user.merci_pincode}</td>
                        </tr>
                        <tr>
                          <td>Gender</td>
                          <td>{user.merci_gender}</td>
                        </tr>
                        <tr>
                          <td>Date of Birth</td>
                          <td>{user.merci_dob}</td>
                        </tr>
                        <tr>
                          <td>Qualification</td>
                          <td>{user.merci_qualification}</td>
                        </tr>
                        <tr>
                          <td>Level</td>
                          <td>{user.merci_level}</td>
                        </tr>
                        <tr>
                          <td>Pan Card</td>
                          <td>{user.merci_pancard}</td>
                        </tr>
                        <tr>
                          <td>Aadhar Card</td>
                          <td>{user.merci_aadhar}</td>
                        </tr>
                        

                      </tbody>
                    </table>
                    }
                    { user.merci_level === 'Level 5' && 
                    <table class="table table-borderless mb-0">
                      <tbody>
                      <tr>
                          <td>Shop Name</td>
                          <td>{user.merci_shop_name}</td>
                        </tr>
                        <tr>
                          <td>Username</td>
                          <td>{user.merci_user_name}</td>
                        </tr>
                        <tr>
                          <td>Full Name</td>
                          <td>{user.merci_full_name}</td>
                        </tr>
                        <tr>
                          <td>Phone</td>
                          <td>{user.merci_phone}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>{user.merci_email}</td>
                        </tr>
                        <tr>
                          <td>Address </td>
                          <td>{user.merci_street_one},{user.merci_street_two},
                          {user.merci_village},{user.merci_mandal},{user.merci_district},
                          {user.merci_pincode}</td>
                        </tr>
                      </tbody>
                    </table>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
