import axios from "axios";
import { ROOFTOPURL } from "../constants/constants";
import RooftopList from "../Pages/Rooftop/RooftopList";

export const createRooftopSHop = async shopDetails => {
    const res = await axios.post(`${ROOFTOPURL}/addrooftop`, {shopDetails} ).then( res => res.data);
    return res;
}

export const checkRooftopUserName = async userName => {
    const res = await axios.post(`${ROOFTOPURL}/checkusername`, {userName} ).then( res => res.data);
    return res;
}

export const getRooftoplist = async userName => {
    const res = await axios.get(`${ROOFTOPURL}/rooftoplist/${userName}`).then( res => res.data);
    return res;
}

export const rooftopChange = async (id, val) => {
    const res = await axios.post(`${ROOFTOPURL}/rooftopActive`, {id: id, val: val}).then( res => res.data);
    return res;
}

export const getRooftopShop = async userName => {
    const shopDetails = await axios.post(`${ROOFTOPURL}/getshop`,{userName}).then( res => res.data);
    return shopDetails;
}

export const getTransactionList = async merchantId => {
    const trans = await axios.post(`${ROOFTOPURL}/transactionlist`,{merchantId}).then( res => res.data);
    return trans;
}

export const addrooftopProduct = async prodData => {
    const trans = await axios.post(`${ROOFTOPURL}/addProduct`,{prodData}).then( res => res.data);
    return trans;
}

export const getrooftopProduct = async () => {
    const trans = await axios.get(`${ROOFTOPURL}/getProduct`).then( res => res.data);
    return trans;
}
export const deleterooftopProduct = async id => {
    const trans = await axios.delete(`${ROOFTOPURL}/product/${id}`).then( res => res.data);
    return trans;
}

export const getMonthlyList = async merchantId => {
    const trans = await axios.post(`${ROOFTOPURL}/monthlylist`,{merchantId}).then( res => res.data);
    return trans;
}