import React, {useState, useEffect} from "react";
import "./TreeView.css";
import HeaderComp from "../../Components/HeaderComp";
import { fetchHierarchy, getUsersList } from "../../api/checkValidUser";
import { useSelector } from "react-redux";

const TreeView = () => {
    const [users, setUsers] = useState([]);
    const loggedUser = useSelector(state =>  state.user.user) || {}

    useEffect(() => {
      getUsers();
    }, [loggedUser]);
  
    const getUsers = async () => {
      if(loggedUser.merci_refer_id){
      const users = await fetchHierarchy(loggedUser.merci_refer_id, loggedUser.merci_level.replace(' ',''));
      console.log(users);
      }
    };
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Customers" />
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <figure>
                    <figcaption>Trew View of Users</figcaption>
                    <ul class="tree">
                      <li>
                        <code>Pramod Kumar Koppu</code>
                        <ul>{users &&
                          users.map((user, index) => {
                            return (
                             <li><code>{`${user.merci_first_name} ${user.merci_middle_name} ${user.merci_last_name}`}</code></li>
                            );
                          })}
                        </ul>
                           
                      </li>
                    </ul>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreeView;
