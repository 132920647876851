export const menu = [
  {
    title: "Dashboard",
    icon: "ri-dashboard-line",
    link: "/",
  },
  {
    title: "Finance Dashboard",
    icon: "ri-mac-line",
    subItems: [
      {
        title: "Analytics",
        icon: "ri-file-chart-line",
        link: "/analytics",
      },
      {
        title: "Banking",
        icon: "ri-bank-fill",
        link: "/banking",
      },
      {
        title: "Utility Bills",
        icon: "ri-bank-card-2-fill",
        link: "/bills",
      },
    ],
  },
  {
    title: " Rooftop Solar",
    icon: "fas fa-solar-panel",
    subItems: [
      {
        title: "Register Shop",
        link: "/registersolarshop",
        icon: "fab fa-wpforms",
      },
      {
        title: "Shops List",
        link: "/shopsList",
        icon: "fas fa-list",
      },
      {
        title: "Shop Details",
        link: "/solarshopdetails",
        icon: "ri-store-2-line",
      },
      {
        title: "Add Shop Products",
        link: "/addshopproduct",
        icon: "ri-layout-5-line",
      },
      {
        title: "Products List",
        link: "/shopsproductList",
        icon: "fas fa-list",
      },
      // {
      //   title: "Edit Shop",
      //   link: "/editsolarshop",
      //   icon: "ri-edit-box-line",
      // },
    ],
  },
  {
    title: "Associates",
    icon: "ri-account-circle-fill",
    subItems: [
      {
        title: "Associate List",
        link: "/userlist",
        icon: "ri-contacts-line",
      },
      {
        title: "Add New Associate",
        link: "/useradd",
        icon: "ri-user-add-line",
      },
      {
        title: "Find Associate",
        link: "/userfind",
        icon: "ri-user-search-line",
      },
      // {
      //   title: "Tree View",
      //   link: "/treeView",
      //   icon: "fas fa-network-wired",
      // },
    ],
  },
  {
    title: "Commission's",
    icon: "ri-mac-line",
    subItems: [
      {
        title: "Rooftop Shop Payment",
        icon: "ri-file-chart-line",
        link: "/rooftopPayments",
      },
      {
        title: "Green Money",
        icon: "ri-bank-fill",
        link: "/greenPayments",
      },
      {
        title: "Purchase Commission",
        icon: "ri-shopping-bag-2-line",
        link: "/purchasePayments",
      }
    ]
  },
  {
    title: "EMart Users",
    icon: "fas fa-users",
    subItems: [
      {
        title: "Find User",
        link: "/emartuserfind",
        icon: "ri-user-search-line",
      },
      {
        title: "Orders",
        link: "/emartorders",
        icon: "far fa-list-alt",
      },
      {
        title: "News Letter",
        link: "/emartnewsletter",
        icon: "fas fa-newspaper",
      },
      {
        title: "Contact Us Info",
        link: "/emartcontact",
        icon: "fas fa-headset",
      },
    ],
  },
  {
    title: "Marketing",
    icon: "ri-store-2-line",
    subItems: [
      {
        title: "Instagram Marketing",
        icon: "ri-instagram-fill",
        link: "/instagramUsers",
      },
      {
        title: "Facebook Marketing",
        icon: "ri-facebook-box-fill",
        link: "/facebookUsers",
      },
    ],
  },
  // {
  //   title: "Doba E-Commerce",
  //   icon: "ri-shopping-bag-2-line",
  //   subItems: [
  //     {
  //       title: "Categories",
  //       link: "/categories",
  //       icon: "ri-file-list-2-line",
  //     },
  //     {
  //       title: "Products",
  //       link: "/products",
  //       icon: "ri-shopping-bag-2-line",
  //     },
  //     {
  //       title: "Search Product",
  //       link: "/productsearch",
  //       icon: " ri-file-search-line",
  //     },
  //     {
  //       title: "Adjust Price",
  //       link: "/adjustprice",
  //       icon: "ri-money-dollar-box-line",
  //     },
  //     {
  //       title: "Hot Product",
  //       link: "/producthot",
  //       icon: "ri-fire-line",
  //     },
  //     {
  //       title: "Valuable Product",
  //       link: "/productvaluable",
  //       icon: "ri-currency-line",
  //     },
  //     {
  //       title: "Orders",
  //       link: "/orders",
  //       icon: "ri-shopping-basket-line",
  //     },
  //     {
  //       title: "Customers",
  //       link: "/customers",
  //       icon: "ri-user-2-line",
  //     },
  //     {
  //       title: "Add Product",
  //       link: "/addproduct",
  //       icon: "ri-file-add-line",
  //     },
  //     {
  //       title: "Shops",
  //       link: "/shops",
  //       icon: "ri-store-3-line",
  //     },
  //   ],
  // },
  // {
  //   title: "Meesho E-Commerce",
  //   icon: "ri-shopping-bag-2-line",
  //   subItems: [
  //     {
  //       title: "Categories",
  //       link: "/meeshocategories",
  //       icon: "ri-file-list-2-line",
  //     },
  //     {
  //       title: "Products",
  //       link: "/meeshoproducts",
  //       icon: "ri-shopping-bag-2-line",
  //     },
  //     {
  //       title: "Search Product",
  //       link: "/meeshoproductsearch",
  //       icon: " ri-file-search-line",
  //     },
  //     {
  //       title: "Adjust Price",
  //       link: "/meeshoadjustprice",
  //       icon: "ri-money-dollar-box-line",
  //     },
  //     {
  //       title: "Hot Product",
  //       link: "/meeshoproducthot",
  //       icon: "ri-fire-line",
  //     },
  //     {
  //       title: "Valuable Product",
  //       link: "/meeshoproductvaluable",
  //       icon: "ri-currency-line",
  //     },
  //     {
  //       title: "Orders",
  //       link: "/meeshoorders",
  //       icon: "ri-shopping-basket-line",
  //     },
  //     {
  //       title: "Customers",
  //       link: "/meeshocustomers",
  //       icon: "ri-user-2-line",
  //     },
  //     {
  //       title: "Add Product",
  //       link: "/meeshoaddproduct",
  //       icon: "ri-file-add-line",
  //     },
  //     {
  //       title: "Shops",
  //       link: "/meeshoshops",
  //       icon: "ri-store-3-line",
  //     },
  //   ],
  // },
  {
    title: "CJ E-Commerce",
    icon: "ri-shopping-bag-2-line",
    subItems: [
      {
        title: "Categories",
        link: "/cjcategories",
        icon: "ri-file-list-2-line",
      },
      {
        title: "Products",
        link: "/cjproducts",
        icon: "ri-shopping-bag-2-line",
      },
      {
        title: "Search Product",
        link: "/cjproductsearch",
        icon: " ri-file-search-line",
      },
      {
        title: "Adjust Price",
        link: "/cjadjustprice",
        icon: "ri-money-dollar-box-line",
      },
      {
        title: "Hot Product",
        link: "/cjproducthot",
        icon: "ri-fire-line",
      },
      {
        title: "Valuable Product",
        link: "/cjproductvaluable",
        icon: "ri-currency-line",
      },
      {
        title: "Add Product",
        link: "/cjaddproduct",
        icon: "ri-file-add-line",
      },
    ],
  },
  // {
  //   title: "Baap E-Commerce",
  //   icon: "ri-shopping-bag-2-line",
  //   subItems: [
  //     {
  //       title: "Categories",
  //       link: "/baapcategories",
  //       icon: "ri-file-list-2-line",
  //     },
  //     {
  //       title: "Products",
  //       link: "/baapproducts",
  //       icon: "ri-shopping-bag-2-line",
  //     },
  //     {
  //       title: "Search Product",
  //       link: "/baapproductsearch",
  //       icon: " ri-file-search-line",
  //     },
  //     {
  //       title: "Adjust Price",
  //       link: "/baapadjustprice",
  //       icon: "ri-money-dollar-box-line",
  //     },
  //     {
  //       title: "Hot Product",
  //       link: "/baapproducthot",
  //       icon: "ri-fire-line",
  //     },
  //     {
  //       title: "Valuable Product",
  //       link: "/baapproductvaluable",
  //       icon: "ri-currency-line",
  //     },
  //     {
  //       title: "Orders",
  //       link: "/baaporders",
  //       icon: "ri-shopping-basket-line",
  //     },
  //     {
  //       title: "Customers",
  //       link: "/baapcustomers",
  //       icon: "ri-user-2-line",
  //     },
  //     {
  //       title: "Add Product",
  //       link: "/baapaddproduct",
  //       icon: "ri-file-add-line",
  //     },
  //     {
  //       title: "Shops",
  //       link: "/baapshops",
  //       icon: "ri-store-3-line",
  //     },
  //   ],
  // },
  // {
  //   title: "Deodap E-Commerce",
  //   icon: "ri-shopping-bag-2-line",
  //   subItems: [
  //     {
  //       title: "Categories",
  //       link: "/deodapcategories",
  //       icon: "ri-file-list-2-line",
  //     },
  //     {
  //       title: "Products",
  //       link: "/deodapproducts",
  //       icon: "ri-shopping-bag-2-line",
  //     },
  //     {
  //       title: "Search Product",
  //       link: "/deodapproductsearch",
  //       icon: " ri-file-search-line",
  //     },
  //     {
  //       title: "Adjust Price",
  //       link: "/deodapadjustprice",
  //       icon: "ri-money-dollar-box-line",
  //     },
  //     {
  //       title: "Hot Product",
  //       link: "/deodapproducthot",
  //       icon: "ri-fire-line",
  //     },
  //     {
  //       title: "Valuable Product",
  //       link: "/deodapproductvaluable",
  //       icon: "ri-currency-line",
  //     },
  //     {
  //       title: "Orders",
  //       link: "/deodaporders",
  //       icon: "ri-shopping-basket-line",
  //     },
  //     {
  //       title: "Customers",
  //       link: "/deodapcustomers",
  //       icon: "ri-user-2-line",
  //     },
  //     {
  //       title: "Add Product",
  //       link: "/deodapaddproduct",
  //       icon: "ri-file-add-line",
  //     }
  //   ],
  // },
  {
    title: "Phone Numbers",
    icon: "ri-smartphone-line",
    link: "/phonenumbers",
  },
  {
    title: "Email",
    icon: "ri-mail-send-line",
    link: "/email",
  },
  {
    title: "Calendar",
    icon: "ri-calendar-2-line",
    link: "/calendar",
  },
];

export const menuLevel2 = [
  {
    title: "Dashboard",
    icon: "ri-dashboard-line",
    link: "/",
  },
  {
    title: " Rooftop Solar",
    icon: "fas fa-solar-panel",
    subItems: [
      {
        title: "Register Shop",
        link: "/registersolarshop",
        icon: "fab fa-wpforms",
      },
      {
        title: "Shops List",
        link: "/shopsList",
        icon: "fas fa-list",
      },
      {
        title: "Shop Details",
        link: "/solarshopdetails",
        icon: "ri-store-2-line",
      },
      // {
      //   title: "Edit Shop",
      //   link: "/editsolarshop",
      //   icon: "ri-edit-box-line",
      // },
    ],
  },
  {
    title: "Associates",
    icon: "ri-account-circle-fill",
    subItems: [
      {
        title: "Associate List",
        link: "/userlist",
        icon: "ri-contacts-line",
      },
      {
        title: "Add New Associate",
        link: "/useradd",
        icon: "ri-user-add-line",
      },
      {
        title: "Find Associate",
        link: "/userfind",
        icon: "ri-user-search-line",
      },
      // {
      //   title: "Tree View",
      //   link: "/treeView",
      //   icon: "fas fa-network-wired",
      // },
    ],
  },
  {
    title: "Commission's",
    icon: "ri-mac-line",
    subItems: [
      {
        title: "Rooftop Shop Payment",
        icon: "ri-file-chart-line",
        link: "/rooftopPayments",
      },
      {
        title: "Green Money",
        icon: "ri-bank-fill",
        link: "/greenPayments",
      },
      {
        title: "Purchase Commission",
        icon: "ri-shopping-bag-2-line",
        link: "/purchasePayments",
      }
    ]
  },
  {
    title: "Email",
    icon: "ri-mail-send-line",
    link: "/email",
  },
  {
    title: "Calendar",
    icon: "ri-calendar-2-line",
    link: "/calendar",
  },
];

export const menuLevel3 = [
  {
    title: "Dashboard",
    icon: "ri-dashboard-line",
    link: "/",
  },
  {
    title: " Rooftop Solar",
    icon: "fas fa-solar-panel",
    subItems: [
      {
        title: "Register Shop",
        link: "/registersolarshop",
        icon: "fab fa-wpforms",
      },
      {
        title: "Shops List",
        link: "/shopsList",
        icon: "fas fa-list",
      },
      {
        title: "Shop Details",
        link: "/solarshopdetails",
        icon: "ri-store-2-line",
      },
      // {
      //   title: "Edit Shop",
      //   link: "/editsolarshop",
      //   icon: "ri-edit-box-line",
      // },
    ],
  },
  {
    title: "Associates",
    icon: "ri-account-circle-fill",
    subItems: [
      {
        title: "Associate List",
        link: "/userlist",
        icon: "ri-contacts-line",
      },
      {
        title: "Add New Associate",
        link: "/useradd",
        icon: "ri-user-add-line",
      },
      {
        title: "Find Associate",
        link: "/userfind",
        icon: "ri-user-search-line",
      },
      // {
      //   title: "Tree View",
      //   link: "/treeView",
      //   icon: "fas fa-network-wired",
      // },
    ],
  },
  {
    title: "Commission's",
    icon: "ri-mac-line",
    subItems: [
      {
        title: "Rooftop Shop Payment",
        icon: "ri-file-chart-line",
        link: "/rooftopPayments",
      },
      {
        title: "Green Money",
        icon: "ri-bank-fill",
        link: "/greenPayments",
      },
      {
        title: "Purchase Commission",
        icon: "ri-shopping-bag-2-line",
        link: "/purchasePayments",
      }
    ],
  },
  {
    title: "Email",
    icon: "ri-mail-send-line",
    link: "/email",
  },
  {
    title: "Calendar",
    icon: "ri-calendar-2-line",
    link: "/calendar",
  },
];

export const menuLevel4 = [
  {
    title: "Dashboard",
    icon: "ri-dashboard-line",
    link: "/",
  },
  {
    title: " Rooftop Solar",
    icon: "fas fa-solar-panel",
    subItems: [
      {
        title: "Register Shop",
        link: "/registersolarshop",
        icon: "fab fa-wpforms",
      },
      {
        title: "Shops List",
        link: "/shopsList",
        icon: "fas fa-list",
      },
      {
        title: "Shop Details",
        link: "/solarshopdetails",
        icon: "ri-store-2-line",
      },
      // {
      //   title: "Edit Shop",
      //   link: "/editsolarshop",
      //   icon: "ri-edit-box-line",
      // },
    ],
  },
  {
    title: "Commission's",
    icon: "ri-money-dollar-box-line",
    subItems: [
      {
        title: "Rooftop Shop Payment",
        icon: "ri-home-gear-line",
        link: "/rooftopPayments",
      },
      {
        title: "Green Money",
        icon: "ri-shopping-bag-2-line",
        link: "/greenPayments",
      },
      {
        title: "Purchase Commission",
        icon: "ri-shopping-bag-2-line",
        link: "/purchasePayments",
      }
    ]
  },
  {
    title: "Email",
    icon: "ri-mail-send-line",
    link: "/email",
  },
  {
    title: "Calendar",
    icon: "ri-calendar-2-line",
    link: "/calendar",
  },
];

export const menuLevel6 = [
  {
    title: "Dashboard",
    icon: "ri-dashboard-line",
    link: "/",
  },
  {
    title: "Doba E-Commerce",
    icon: "ri-shopping-bag-2-line",
    subItems: [
      {
        title: "Categories",
        link: "/categories",
        icon: "ri-file-list-2-line",
      },
      {
        title: "Products",
        link: "/products",
        icon: "ri-shopping-bag-2-line",
      },
      {
        title: "Search Product",
        link: "/productsearch",
        icon: " ri-file-search-line",
      },
      {
        title: "Hot Product",
        link: "/producthot",
        icon: "ri-fire-line",
      },
      {
        title: "Valuable Product",
        link: "/productvaluable",
        icon: "ri-currency-line",
      },
      {
        title: "Add Product",
        link: "/addproduct",
        icon: "ri-file-add-line",
      },
    ],
  },
  {
    title: "Meesho E-Commerce",
    icon: "ri-shopping-bag-2-line",
    subItems: [
      {
        title: "Categories",
        link: "/meeshocategories",
        icon: "ri-file-list-2-line",
      },
      {
        title: "Products",
        link: "/meeshoproducts",
        icon: "ri-shopping-bag-2-line",
      },
      {
        title: "Search Product",
        link: "/meeshoproductsearch",
        icon: " ri-file-search-line",
      },
      {
        title: "Hot Product",
        link: "/meeshoproducthot",
        icon: "ri-fire-line",
      },
      {
        title: "Valuable Product",
        link: "/meeshoproductvaluable",
        icon: "ri-currency-line",
      },
      {
        title: "Add Product",
        link: "/meeshoaddproduct",
        icon: "ri-file-add-line",
      },
    ],
  },
  {
    title: " CJ E-Commerce",
    icon: "ri-shopping-bag-2-line",
    subItems: [
      {
        title: "Categories",
        link: "/cjcategories",
        icon: "ri-file-list-2-line",
      },
      {
        title: "Products",
        link: "/cjproducts",
        icon: "ri-shopping-bag-2-line",
      },
      {
        title: "Search Product",
        link: "/cjproductsearch",
        icon: " ri-file-search-line",
      },
      {
        title: "Hot Product",
        link: "/cjproducthot",
        icon: "ri-fire-line",
      },
      {
        title: "Valuable Product",
        link: "/cjproductvaluable",
        icon: "ri-currency-line",
      },
      {
        title: "Add Product",
        link: "/cjaddproduct",
        icon: "ri-file-add-line",
      },
    ],
  },
  {
    title: " Baap E-Commerce",
    icon: "ri-shopping-bag-2-line",
    subItems: [
      {
        title: "Categories",
        link: "/baapcategories",
        icon: "ri-file-list-2-line",
      },
      {
        title: "Products",
        link: "/baapproducts",
        icon: "ri-shopping-bag-2-line",
      },
      {
        title: "Search Product",
        link: "/cjproductsearch",
        icon: " ri-file-search-line",
      },
      {
        title: "Hot Product",
        link: "/baapproducthot",
        icon: "ri-fire-line",
      },
      {
        title: "Valuable Product",
        link: "/baapproductvaluable",
        icon: "ri-currency-line",
      },
      {
        title: "Add Product",
        link: "/baapaddproduct",
        icon: "ri-file-add-line",
      },
    ],
  },
  {
    title: "Email",
    icon: "ri-mail-send-line",
    link: "/email",
  },
  {
    title: "Calendar",
    icon: "ri-calendar-2-line",
    link: "/calendar",
  },
];

export const menuLevel5 = [
  {
    title: "Dashboard",
    icon: "ri-dashboard-line",
    link: "/",
  },
  {
    title: "Coupons",
    icon: "ri-gift-line",
    subItems: [
      {
        title: "Generate Coupons",
        icon: "ri-gift-line",
        link: "/coupons",
      },
      {
        title: "Coupons List",
        icon: "ri-file-list-3-line",
        link: "/couponslist",
      },
    ],
  },
  {
    title: "Purchase",
    icon: "ri-shopping-bag-2-line",
    link: "/purchaseProducts",
  },
  {
    title: "Transactions",
    icon: "ri-money-dollar-box-line",
    link: "/greentransactions",
  },
  {
    title: "Notifications",
    icon: "ri-notification-3-line",
    link: "/rooftopNotifications",
  },
];
