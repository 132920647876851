import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";
import {
  menu,
  menuLevel2,
  menuLevel3,
  menuLevel4,
  menuLevel5,
  menuLevel6,
} from "../../constants/MenuConstants";

const SidebarMenuItem = ({ item }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <li>
      {item.subItems ? (
        <a
          href="#"
          className={`waves-effect has-arrow`}
          onClick={toggleSubMenu}
        >
          <i className={item.icon}></i>
          <span>{item.title}</span>
        </a>
      ) : (
        <Link to={item.link || "#"} className="waves-effect">
          <i className={item.icon}></i>
          <span>{item.title}</span>
        </Link>
      )}
      {item.subItems && isSubMenuOpen && (
        <ul className="sub-menu" aria-expanded="false">
          {item.subItems.map((subItem, index) => (
            <SidebarMenuItem key={index} item={subItem} />
          ))}
        </ul>
      )}
    </li>
  );
};

const SideMenu = () => {
  const userData = useSelector((state) => state.user.user);

  return (
    <div className="vertical-menu">
      <div data-simplebar className="h-100">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {userData?.merci_level === "Level 1" &&
              menu.map((menuItem, index) => (
                <SidebarMenuItem key={index} item={menuItem} />
              ))}
            {userData?.merci_level === "Level 2" &&
              menuLevel2.map((menuItem, index) => (
                <SidebarMenuItem key={index} item={menuItem} />
              ))}
            {userData?.merci_level === "Level 3" &&
              menuLevel3.map((menuItem, index) => (
                <SidebarMenuItem key={index} item={menuItem} />
              ))}
            {userData?.merci_level === "Level 4" &&
              menuLevel4.map((menuItem, index) => (
                <SidebarMenuItem key={index} item={menuItem} />
              ))}
            {userData?.merci_level === "Level 5" &&
              menuLevel5.map((menuItem, index) => (
                <SidebarMenuItem key={index} item={menuItem} />
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
