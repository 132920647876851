import React from "react";

const ProductPagination = ({
  page,
  totalPages,
  handleDecrement,
  handleIncrement,
  handlePageClick,
}) => {
  return (
    <div className="row g-0">
      <div className="col-sm-12 col-md-12">
        <div className="center">
          <div className="pagination">
            <span onClick={handleDecrement}>
              <i className="fa fa-chevron-left"></i>
            </span>
            {page > 3 && <span className="dots">...</span>}
            <span
              className={page === 1 ? "active" : ""}
              onClick={() =>
                handlePageClick(
                  page === 1 ? 1 : page === totalPages ? page - 2 : page - 1
                )
              }
            >
              {page === 1 ? 1 : page === totalPages ? page - 2 : page - 1}
            </span>
            <span className={page !== 1 && page !== totalPages ? "active" : ""}>
              {page === 1 ? page + 1 : page === totalPages ? page - 1 : page}
            </span>
            <span
              className={page === totalPages ? "active" : ""}
              onClick={() =>
                handlePageClick(
                  page === 1 ? page + 2 : page === totalPages ? page : page + 1
                )
              }
            >
              {page === 1 ? page + 2 : page === totalPages ? page : page + 1}
            </span>
            {page < totalPages - 3 && <span className="dots">...</span>}
            <span>of {totalPages}</span>
            <span onClick={handleIncrement}>
              <i className="fa fa-chevron-right"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPagination;
