import React, { useState } from "react";
import HeaderComp from "../../Components/HeaderComp";
import { useSelector } from "react-redux";
import { checkRooftopUserName, createRooftopSHop } from "../../api/RooftopApi";

const AddRooftopShop = () => {
  const fields = {
    merci_shop_name: "",
    merci_user_name: "",
    merci_password: "",
    merci_confirm_password: "",
    merci_full_name: "",
    merci_email: "",
    merci_phone: "",
    merci_street_one: "",
    merci_street_two: "",
    merci_village: "",
    merci_mandal: "",
    merci_district: "",
    merci_state: "",
    merci_pincode: "",
    merci_level: "Level 5",
    merci_refer: "",
    merci_isActive: true,
    merci_isPayment: false,
    merci_green_money: "200000",
  };
  const [userForm, setUserForm] = useState(fields);
  const [isActive, setIsActive] = useState(true);
  const [phoneAvailable, setPhoneAvailable] = useState("");
  const user = useSelector(state => state.user.user);

  const onChangeForm = (e) => {
    let val;
    if (e.target.name === "phone") {
      val = e.target.value.slice(0, 10);
    } else {
      val = e.target.value;
    }
    setUserForm({...userForm, [e.target.name]: val});
  };

  const checkPaswords = () => {
    if(userForm.merci_password !== userForm.merci_confirm_password){
      alert('Password and Confirm Password should be same');
    } 
  }

  const checkUserName = async () => {
    const res = await checkRooftopUserName(userForm.merci_user_name);
    if(res.status === 200){
      setIsActive(false)
    } else {
      alert(res.message);
    }
  }

  const onFormSubmit = async () => {

    let modifiedUserForm;

    if(userForm.merci_password !== userForm.merci_confirm_password){
      alert('Password and Confirm Password should be same');
    } else {
      delete userForm.merci_confirm_password;
      modifiedUserForm = {...userForm, merci_refer: user?.merci_refer_id };
      const addedUser = await createRooftopSHop(modifiedUserForm);
      if(addedUser.status === 200){
        alert('Member has been created Succesfully');
        window.location.reload();
        // onChangeAddUser();
      }else{
        alert('Please Try again, there is an issue creating the Member');
      }
  }
    
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Create New Solar Rooftop Shop" />
          <div
            className="row"
            style={{ backgroundColor: "#FFF", padding: "10px" }}
          >
            <div className="col-lg-12">
              <div className="white_card card_height_100 mb_30">
                <div className="white_card_body">
                  <div className="card-body">
                    {/* Name Field  start   */}
                    <h5 className="m-0">Shop Details</h5>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="inputEmail4">
                          Shop Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Shop Name"
                          name="merci_shop_name"
                          required
                          value={userForm.merci_shop_name}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label className="form-label" htmlFor="inputEmail4">
                          User Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="User Name"
                          name="merci_user_name"
                          required
                          value={userForm.merci_user_name}
                          onChange={(e) => onChangeForm(e)}
                          onBlur={checkUserName}
                        />
                      </div>
                      <div className=" col-md-4">
                        <label className="form-label" htmlFor="inputPassword4">
                          Password<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          name="merci_password"
                          required
                          value={userForm.merci_password}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                      <div className=" col-md-4">
                        <label className="form-label" htmlFor="inputPassword4">
                          Confirm Password
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Confirm Password"
                          name="merci_confirm_password"
                          required
                          value={userForm.merci_confirm_password}
                          onChange={(e) => onChangeForm(e)}
                          onBlur={checkPaswords}
                        />
                      </div>
                    </div>
                    {/* Name Field  start   */}
                    <h5 className="m-0">Full Name</h5>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="inputEmail4">
                          Full Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Full Name"
                          name="merci_full_name"
                          required
                          value={userForm.merci_full_name}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>

                    {/* Name Field  End   */}
                    {/* Phone Email Password  start   */}
                    <h5 className="m-0">Phone & Email</h5>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          name="merci_email"
                          value={userForm.merci_email}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                      <div className=" col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Phone<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone Number"
                          name="merci_phone"
                          value={userForm.merci_phone}
                          onChange={(e) => onChangeForm(e)}
                        />
                        <p style={{ color: "red" }}>{phoneAvailable}</p>
                      </div>
                    </div>
                    {/* Phone Email  End   */}
                    {/* Street Start  */}
                    <h5 className="m-0">Address</h5>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Flat No / House No
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Flat No / House No"
                          name="merci_street_one"
                          value={userForm.merci_street_one}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                      <div className=" col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Street Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Street Name"
                          name="merci_street_two"
                          value={userForm.merci_street_two}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className=" col-md-4">
                        <label className="form-label" htmlFor="inputCity">
                          Village
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Village"
                          name="merci_village"
                          value={userForm.merci_village}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                      <div className=" col-md-4">
                        <label className="form-label" htmlFor="inputCity">
                          Mandal
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mandal"
                          name="merci_mandal"
                          value={userForm.merci_mandal}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                      <div className=" col-md-4">
                        <label className="form-label" htmlFor="inputCity">
                          District
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="District"
                          name="merci_district"
                          value={userForm.merci_district}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          State
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="State"
                          name="merci_state"
                          value={userForm.merci_state}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                      <div className=" col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Pincode
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Pincode"
                          name="merci_pincode"
                          value={userForm.merci_pincode}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>
                    {/* Stree End */}
                    <h5 className="m-0">
                      Registered By<span style={{ color: "red" }}>*</span>
                    </h5>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={user?.merci_refer_id}
                          disabled=""
                        />
                      </div>
                    </div>

                    <hr />

                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={onFormSubmit}
                      disabled={isActive}
                    >
                      Create User
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRooftopShop;
