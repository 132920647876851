import React from 'react'
import './Popup.css'

const Popup = ({ isOpen, onClose, children }) => {
    return (
        <>
          {isOpen && (
            <div className="popup-overlay">
              <div className="popup">
                <button className="close-btn" onClick={onClose}>Close</button>
                {children}
              </div>
            </div>
          )}
        </>
      );
    };

export default Popup