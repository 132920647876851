import React, { useState, useEffect } from "react";
import { getStates } from "../../api/bloodData";

const StatesList = ({getSelectedCategories}) => {
  const [catlist, setCatlist] = useState([]);
  const [mainlist, setmainlist] = useState([]);
  const [mainselect, setmainselect] = useState([]);
  const [sublist, setsublist] = useState([]);
  const [subselect, setsubselect] = useState([]);
  const [lowlist, setlowlist] = useState([]);
  const [lowselect, setlowselect] = useState([]);
  const [changed, setChanged] = useState(false);

  const getCatList = async () => {
    const list = await getStates();
    setCatlist(list);
    const mainlistfilter = [
      ...new Set(list?.map((data) => data.State)),
    ];
    setmainlist(mainlistfilter);
  };
  const onMainSelect = (e) => {
    const sublist = catlist.filter((data) => data.State === e.target.value);
    const sublistfilter = [...new Set(sublist?.map((data) => data.District))];
    
    setmainselect(e.target.value);
    setsublist(sublistfilter);
  };

  const onSubSelect = (e) => {
    const lowlist = catlist.filter(
      (data) => data.State === mainselect && data.District === e.target.value
    );
    const lowlistfilter = [...new Set(lowlist?.map((data) => data.City))];
    setsubselect(e.target.value);
    setlowlist(lowlistfilter);
    
  };

  const onLowSelect = (e) => {
    setlowselect(e.target.value);
    getSelectedCategories(mainselect, subselect, e.target.value);
  }

  const getMainlist = () => {
    const mainlistfilter = [...new Set(catlist?.map((data) => data.main_cat))];
    setmainlist(mainlistfilter);
    setChanged(!changed);
  };
  useEffect(() => {
    getCatList();
    getMainlist();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-0">
            <label className="form-label">Select State</label>
            <select
              className="form-control select2-search-disable"
              onChange={(e) => onMainSelect(e)}
            >
              <option selected="">Select State</option>
              {mainlist.map((main, index) => (
                <option key={index} value={main}>
                  {main}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="mb-0">
            <label className="form-label">Select District</label>
            <select
              className="form-control select2-search-disable"
              onChange={(e) => onSubSelect(e)}
            >
              <option selected="">Select District</option>
              {sublist.map((main, index) => (
                <option key={index} value={main}>
                  {main}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-0">
            <label className="form-label">Select City</label>
            <select
              className="form-control select2-search-disable"
              onChange={(e) => onLowSelect(e)}
            >
              <option selected="">Select City</option>
              {lowlist.map((main, index) => (
                <option key={index} value={main}>
                  {main}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatesList;
