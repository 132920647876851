function generateCouponCode(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let couponCode = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        couponCode += characters[randomIndex];
    }
    return couponCode;
}

export const generateCodes = (cid, codeLength, amount, denom, user, phone, userId) => {
    const codes = new Set();
    const numCodes = 1;
    while (codes.size < numCodes) {
        codes.add(generateCouponCode(codeLength));
    }

    const couponArray = [];
    let remainingAmount = amount;

    Array.from(codes).forEach((code, index) => {
        couponArray.push({
            merchantId: cid,
            coupon: code,
            value: amount,
            assignedTo: user,
            assignedPhone: phone,
            assignedId: userId,
            used: 0
        });
        remainingAmount -= amount;
    });

    return couponArray;
}