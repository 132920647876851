import React from 'react'
import HeaderComp from '../../Components/HeaderComp'

const EditUser = () => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Edit Users" />
        </div>
      </div>
    </div>
  )
}

export default EditUser