import React from 'react'
import HeaderComp from '../../Components/HeaderComp'

const Wallet = () => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Wallet" />
        </div>
      </div>
    </div>
  )
}

export default Wallet