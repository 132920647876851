import React, { useState } from "react";
import { useSelector } from "react-redux";
import HeaderComp from "../../Components/HeaderComp";
import { addrooftopProduct } from "../../api/RooftopApi";

const RooftopProducts = () => {
  const fields = {
    merci_title: "",
    merci_description: "",
    merci_price: "",
    merci_quantity: "",
  };
  const [userForm, setUserForm] = useState(fields);

  const onChangeForm = (e) => {
    let val;
    val = e.target.value;
    setUserForm({ ...userForm, [e.target.name]: val });
  };

  const onFormSubmit = async () => {
    const addedUser = await addrooftopProduct(userForm);
    if (addedUser.status === 200) {
    alert("Product has been created Succesfully");
    window.location.reload();
    } else {
    alert("Please Try again, there is an issue creating the Product");
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Add products for Rooftop Shop" />
          <div
            className="row"
            style={{ backgroundColor: "#FFF", padding: "10px" }}
          >
            <div className="col-lg-12">
              <div className="white_card card_height_100 mb_30">
                <div className="white_card_body">
                  <div className="card-body">
                    {/* Name Field  start   */}
                    <h5 className="m-0">Product Details</h5>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="inputEmail4">
                          Product Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product Title"
                          name="merci_title"
                          required
                          value={userForm.merci_title}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="inputEmail4">
                          Product Description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product Description"
                          name="merci_description"
                          required
                          value={userForm.merci_description}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="inputEmail4">
                          Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Price"
                          name="merci_price"
                          required
                          value={userForm.merci_price}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="inputEmail4">
                          Bag Quantity
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Bag Quantity"
                          name="merci_quantity"
                          required
                          value={userForm.merci_quantity}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={onFormSubmit}
                    >
                      Add Product
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RooftopProducts;
