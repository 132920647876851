import React from "react";
import {
  createOrder,
  createShopOrder,
  paymentMethod,
  paymentVerfication,
} from "../../api/PaymentApi";
import { useNavigate } from "react-router-dom";

const Banner = ({ userData, BannerImg }) => {
  const navigate = useNavigate();

  const generateUniqueId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000);
    const uniqueId = `ORID${timestamp}${random}`;
    return uniqueId;
  };

  const handlePayment = async () => {
    const amount = 3000;
    const currency = "INR";
    const receipt = "0123456789";
    const res = await paymentMethod({
      amount: parseInt(amount * 100),
      currency,
      receipt,
    });

    var option = {
      key: "",
      amount,
      currency,
      name: "Merciplus Tech",
      description: "Test Transaction",
      image: "/img/merci_logo_sm.png",
      order_id: res.id,
      handler: async function (res) {
        const body = { ...res };
        const validateResponse = await paymentVerfication(body);

        if (validateResponse.status === 200) {
          const futureDate = new Date();
          futureDate.setDate(futureDate.getDate() + 7);

          const purchasedData = {
            ORID: generateUniqueId(),
            userId: userData._id,
            orderID: validateResponse.orderId,
            paymentID: validateResponse.paymentId,
            total: amount,
            refer: userData.merci_refer,
            purchasedDate: new Date(),
          };
          await createShopOrder(purchasedData);
          alert("Your order is placed. Thankyou for partnering with us");
          navigate("/");
        } else {
          alert(validateResponse.msg);
        }   
      },
      notes: {
        address: "Merciplus Tech PVT LTD",
      },
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new window.Razorpay(option);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  };

  return (
    <div className="banner">
      <div className="left-content">
        <h1>
          Welcome Back
          {(userData?.merci_level === "Level 1" ||
            userData?.merci_level === "Level 2" || userData?.merci_level === "Level 3" ||
            userData?.merci_level === "Level 4") &&
            ` ${userData?.merci_last_name} ${userData?.merci_first_name}`}
          {userData?.merci_level === "Level 5" && ` ${userData?.merci_full_name}`}
        </h1>
        <p>We're glad to have you back.</p>
        {userData?.merci_level === "Level 5" && !userData?.merci_isPayment && (
          <>
          <p style={{ color: "red" }}>
            Your Payment of Rs 3,000 is Pending. Please pay by clicking on below
          </p>
          <button className="btn btn-primary" onClick={handlePayment}>Pay Now</button>
          </>
        )}
      </div>
      <div className="right-content">
        <img src={BannerImg} alt="Welcome" width="300px" height="300px" />
      </div>
    </div>
  );
};

export default Banner;
