import React from "react";
import { Link } from "react-router-dom";
import HeaderComp from "../../Components/HeaderComp";
import "./Dashboard.css";
import BannerImg from "../../assets/banner_work.png";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux/es/hooks/useSelector";
import Banner from "./Banner";

const Dashboard = () => {
  const userData = useSelector((state) => state.user.user);

  const optionsMixedChart = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    stroke: {
      width: [4, 0, 0],
    },
    xaxis: {
      categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    markers: {
      size: 6,
      strokeWidth: 3,
      fillOpacity: 0,
      strokeOpacity: 0,
      hover: {
        size: 8,
      },
    },
    yaxis: {
      tickAmount: 5,
      min: 0,
      max: 100,
    },
  };

  const seriesMixedChart = [
    {
      name: "series-1",
      type: "line",
      data: [30, 40, 25, 50, 49, 21, 70, 51],
    },
    {
      name: "series-2",
      type: "column",
      data: [23, 12, 54, 61, 32, 56, 81, 19],
    },
    {
      name: "series-3",
      type: "column",
      data: [62, 12, 45, 55, 76, 41, 23, 43],
    },
  ];

  const series = [14, 23, 21, 17, 15, 10, 12, 17, 21];
  const options = {
    chart: {
      type: "polarArea",
    },
    stroke: {
      colors: ["#fff"],
    },
    fill: {
      opacity: 0.8,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };


  

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Dashboard" />

          <Banner userData={userData} BannerImg={BannerImg} />

          {userData?.merci_level === "Level 1" && (
            <>
              <div className="row" style={{ margin: "2rem" }}>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Number of Sales
                          </p>
                          <h4 className="mb-0">1452</h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="ri-stack-line font-size-24"></i>
                        </div>
                      </div>
                    </div>

                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="badge badge-soft-success font-size-11">
                          <i className="mdi mdi-menu-up"> </i> 2.4%{" "}
                        </span>
                        <span className="text-muted ms-2">
                          From previous period
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Sales Revenue
                          </p>
                          <h4 className="mb-0">$ 38452</h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="ri-store-2-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="badge badge-soft-success font-size-11">
                          <i className="mdi mdi-menu-up"> </i> 2.4%{" "}
                        </span>
                        <span className="text-muted ms-2">
                          From previous period
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-1 overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Average Price
                          </p>
                          <h4 className="mb-0">$ 15.4</h4>
                        </div>
                        <div className="text-primary ms-auto">
                          <i className="ri-briefcase-4-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body border-top py-3">
                      <div className="text-truncate">
                        <span className="badge badge-soft-success font-size-11">
                          <i className="mdi mdi-menu-up"> </i> 2.4%{" "}
                        </span>
                        <span className="text-muted ms-2">
                          From previous period
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="float-end d-none d-md-inline-block">
                        <div className="btn-group mb-2">
                          <button
                            type="button"
                            className="btn btn-sm btn-light"
                          >
                            Today
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-light active"
                          >
                            Weekly
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-light"
                          >
                            Monthly
                          </button>
                        </div>
                      </div>
                      <h4 className="card-title mb-4">Revenue Analytics</h4>
                      <div>
                        <div
                          id="line-column-chart"
                          className="apex-charts"
                          dir="ltr"
                        ></div>
                      </div>
                    </div>

                    <div className="card-body border-top text-center">
                      <div className="row">
                        <Chart
                          options={optionsMixedChart}
                          series={seriesMixedChart}
                          type="line"
                          width="500"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="float-end d-none d-md-inline-block">
                        <div className="btn-group mb-2">
                          <button
                            type="button"
                            className="btn btn-sm btn-light"
                          >
                            Today
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-light active"
                          >
                            Weekly
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-light"
                          >
                            Monthly
                          </button>
                        </div>
                      </div>
                      <h4 className="card-title mb-4">Product Analytics</h4>
                      <div>
                        <div
                          id="line-column-chart"
                          className="apex-charts"
                          dir="ltr"
                        ></div>
                      </div>
                    </div>

                    <div className="card-body border-top text-center">
                      <div className="row">
                        <ReactApexChart
                          options={options}
                          series={series}
                          type="polarArea"
                          width="500"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
