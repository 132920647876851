import React, { useState } from "react";
import HeaderComp from "../../Components/HeaderComp";
import { useSelector, useDispatch } from "react-redux";
import { rupee } from "../../constants/priceFormats";
import { generateCodes } from "../../Utils/CoupnsGenerate";
import { insertMultipleCoupons } from "../../api/couponsApi";
import { getUserDetails } from "../../features/user/userSlice";
import { getUserbyPhone } from "../../api/EmartApi";
import { useNavigate } from "react-router-dom";

const CouponsRooftop = () => {
  const userData = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [denom, setDenom] = useState(0);
  const [amnt, setAmnt] = useState();
  const [phone, setPhone] = useState("");
  const [user, setUser] = useState([]);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const getCodes = async () => {
    if (amnt / denom >= 1) {
      const codes = generateCodes(userData._id, 10, amnt, denom, user.merci_full_name, user.merci_phone, user.id);
      const res = await insertMultipleCoupons(codes, amnt, userData._id);
      if (res.status === 200) {
        setAmnt(0);
        setDenom(0);
        dispatch(getUserDetails(userData._id));
      }
      alert(res.message);
      navigate('/couponslist');
    } else {
      alert("Amount cannot be converted please select more");
    }
  };

  const handleUser = async () => {
    if (phone.length === 10) {
      const res = await getUserbyPhone(phone);
      if (res.length === 0) {
        setMsg("No User Found under Phone Number");
      } else {
        setUser(res);
        setMsg("");
      }
    } else {
      alert("Phone number not valid");
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Coupons List" />

          <div className="row" style={{ marginTop: "2rem" }}>
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Generate Coupons</h4>
                  <p className="card-title-desc">
                    Please select amount to convert coupons. Green Money Balance
                    :- {rupee.format(userData?.merci_green_money)}
                  </p>

                  <div className="row mb-3">
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone Number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        onBlur={handleUser}
                      />
                    </div>
                    <div className="col-sm-6">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Amount to add for Coupon"
                        value={amnt}
                        onChange={(e) => setAmnt(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    {msg !== "" ? (
                      <p>{msg}</p>
                    ) : (
                      <p>Name:- {user.merci_full_name}</p>
                    )}
                  </div>
                  <div>
                    { !userData.merci_isPayment && <p style={{color: 'red'}}> Button will be activated after initial payment of Rs 3,000</p> }
                    <button
                      className="btn btn-primary"
                      onClick={getCodes}
                      type="submit"
                      disabled={userData.merci_isPayment ? false : true}
                    >
                      Generate Coupons
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponsRooftop;
