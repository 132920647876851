import React, { useState, useEffect } from "react";
import HeaderComp from "../../Components/HeaderComp";
import { deleteUsers, fetchHierarchy, getUsersList } from "../../api/checkValidUser";
import { useSelector } from "react-redux";

const UsersList = () => {
  const [users, setUsers] = useState([]);

  const loggedUser = useSelector(state =>  state.user.user);

  useEffect(() => {
    getUsers();
  }, [loggedUser]);

  const getUsers = async () => {
    if(loggedUser.merci_refer_id){
    const users = await fetchHierarchy(loggedUser.merci_refer_id, loggedUser.merci_level);
    setUsers(users);
    }
  };

  const deleteUser = async (id) => {
    const res = deleteUsers(id);
    getUsers();
    alert(res.message)
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="List of Users Under You" />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Users List</h4>

                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Full Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Level</th>
                          <th>Pancard</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users &&
                          users.map((user, index) => {
                            return (
                              <tr>
                                <td>{`${user.merci_first_name} ${user.merci_middle_name} ${user.merci_last_name}`}</td>
                                <td>{user.merci_email}</td>
                                <td>{user.merci_phone}</td>
                                <td>{user.merci_level}</td>
                                <td>{user.merci_pancard}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-danger waves-effect waves-light"
                                    onClick={() => deleteUser(user._id)}
                                    disabled={loggedUser.merci_refer_id === user.merci_refer_id}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersList;
