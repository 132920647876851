import React, {useState} from 'react'
import HeaderComp from '../../Components/HeaderComp'
import { setCJValuable } from '../../api/CJApi';

const CJValueProduct = () => {
  const [skucode, setSkucode] = useState("");

  const updateProduct = async (status) =>{
    const res = await setCJValuable(skucode, status);
    alert(res.message);
    setSkucode('');
  }


  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Valued Products" />
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Valued Product</h4>
                  <p className="card-title-desc">
                   Update Product Valued or Not Valued Product
                  </p>

                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Enter SKU of the Product"
                        id="example-search-input"
                        value={skucode}
                        onChange={(e) => setSkucode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={() => updateProduct(true)}
                      type="submit"
                    >
                      Make Product Valuable
                    </button>
                    <button
                      style={{marginLeft: "20px"}}
                      className="btn btn-danger"
                      onClick={() => updateProduct(false)}
                      type="submit"
                    >
                      Remove Product Valuable
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CJValueProduct