import React from 'react'
import { Routes, Route } from "react-router-dom";
import LoginPage from "./Pages/Authentication/LoginPage";
import HomePage from "./Pages/Home/HomePage";
// import AddProduct from './Pages/Products/AddProduct';
// import AdjustPrice from './Pages/Products/AdjustPrice';
// import Categories from './Pages/Products/Categories';
// import Customers from './Pages/Products/Customers';
// import HotProduct from './Pages/Products/HotProduct';
// import SearchProducts from './Pages/Products/SearchProduct';
// import Products from './Pages/Products/Products';
// import Shops from './Pages/Products/Shops';
// import ValuePrdoucts from './Pages/Products/ValueProduct';
import Dashboard from './Components/Dashboard/Dashboard';
// import Orders from './Pages/Products/Orders';
import Calander from './Components/Calander/Calander';
import Kanban from './Pages/Kanban/Kanban';
import Email from './Pages/Email/Email';
import Contacts from './Pages/Contacts/Contacts';
import Bills from './Pages/Bills/Bills';
import Analytics from './Pages/Analytics/Analytics';
import Banking from './Pages/Banking/Banking';
import UsersList from './Pages/Users/UsersList';
import UserAdd from './Pages/Users/UserAdd';
import EditUser from './Pages/Users/EditUser';
import FindUser from './Pages/Users/FindUser';
import InstagramMarket from './Pages/Instagram/InstagramMarket';
import FacebookMarket from './Pages/Facebook/FacebookMarket';
import TreeView from './Pages/Users/TreeView';
// import MeeshoCategories from './Pages/MeeshoProducts/MeeshoCategories';
// import MeeshoCustomers from './Pages/MeeshoProducts/MeeshoCustomers';
// import MeeshoHotProduct from './Pages/MeeshoProducts/MeeshoHotProduct';
// import MeeshoOrders from './Pages/MeeshoProducts/MeeshoOrders';
// import MeeshoProducts from './Pages/MeeshoProducts/MeeshoProducts';
// import MeeshoSearchProduct from './Pages/MeeshoProducts/MeeshoSearchProduct';
// import MeeshoShops from './Pages/MeeshoProducts/MeeshoShops';
// import MeeshoValueProduct from './Pages/MeeshoProducts/MeeshoValueProduct';
// import MeeshoAddProduct from './Pages/MeeshoProducts/MeeshoAddProduct';
// import MeeshoAdjustPrice from './Pages/MeeshoProducts/MeeshoAdjustPrice';
import CJCategories from './Pages/CJProducts/CJCategories';
import CJAddProduct from './Pages/CJProducts/CJAddProduct';
import CJProducts from './Pages/CJProducts/CJProducts';
import CJAdjustPrice from './Pages/CJProducts/CJAdjustPrice';
import CJSearchProduct from './Pages/CJProducts/CJSearchProduct';
import CJHotProduct from './Pages/CJProducts/CJHotProduct';
import CJValueProduct from './Pages/CJProducts/CJValueProduct';
// import BaapShops from './Pages/BaapProducts/BaapShops';
// import BaapAddProduct from './Pages/BaapProducts/BaapAddProduct';
// import BaapCustomers from './Pages/BaapProducts/BaapCustomers';
// import BaapCategories from './Pages/BaapProducts/BaapCategories';
// import BaapProducts from './Pages/BaapProducts/BaapProducts';
// import BaapSearchProduct from './Pages/BaapProducts/BaapSearchProduct';
// import BaapAdjustPrice from './Pages/BaapProducts/BaapAdjustPrice';
// import BaapHotProduct from './Pages/BaapProducts/BaapHotProduct';
// import BaapValueProduct from './Pages/BaapProducts/BaapValueProduct';
// import BaapOrders from './Pages/BaapProducts/BaapOrders';
import AddRooftopShop from './Pages/Rooftop/AddRooftopShop';
import RooftopList from './Pages/Rooftop/RooftopList';
import RooftopShopdetails from './Pages/Rooftop/RooftopShopdetails';
import RooftopEdit from './Pages/Rooftop/RooftopEdit';
import Profile from './Pages/Authentication/Profile';
import Wallet from './Pages/Authentication/Wallet';
import ChangePassword from './Pages/Authentication/ChangePassword';
// import DeodapCategories from './Pages/DeodapProducts/DeodapCategories';
// import DeodapProducts from './Pages/DeodapProducts/DeodapProducts';
// import DeodapSearchProduct from './Pages/DeodapProducts/DeodapSearchProduct';
// import DeodapHotProduct from './Pages/DeodapProducts/DeodapHotProduct';
// import DeodapValueProduct from './Pages/DeodapProducts/DeodapValueProduct';
// import DeodapOrders from './Pages/DeodapProducts/DeodapOrders';
// import DeodapAdjustPrice from  './Pages/DeodapProducts/DeodapAdjustPrice';
// import DeodapCustomers from './Pages/DeodapProducts/DeodapCustomers';
// import DeodapAddProduct from './Pages/DeodapProducts/DeodapAddProduct';
import EmartFindUser from './Pages/Emart/EmartFindUser';
import EmartOrders from './Pages/Emart/EmartOrders';
import NewsLetter from './Pages/Emart/NewsLetter';
import Contactinfo from './Pages/Emart/Contactinfo';
import GreenTransactions from './Pages/RooftopMerchant/GreenTransactions';
import CouponsRooftop from './Pages/RooftopMerchant/CouponsRooftop';
import NotificationsRooftop from './Pages/RooftopMerchant/NotificationsRooftop';
import CouponsList from './Pages/RooftopMerchant/CouponsList';
import CouponsAssign from './Pages/RooftopMerchant/CouponsAssign';
import ShopCommission from './Pages/Commissions/ShopCommission';
import GreenCommission from './Pages/Commissions/GreenCommission';
import RooftopProducts from './Pages/Rooftop/RooftopProducts';
import RooftopProductsList from './Pages/Rooftop/RooftopProductsList';
import PurchaseProduct from './Pages/RooftopMerchant/PurchaseProduct';
import MonthlyCommission from './Pages/Commissions/MonthlyCommission';

const Routers = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Dashboard />} />
      <Route path="/home" element={<HomePage />} />
      {/* Doba Routes */}
      {/* <Route path="/categories" element={<Categories />} />
      <Route path="/products" element={<Products />} />
      <Route path="/productsearch" element={<SearchProducts />} />
      <Route path="/adjustprice" element={<AdjustPrice />} />
      <Route path="/producthot" element={<HotProduct />} />
      <Route path="/productvaluable" element={<ValuePrdoucts />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/customers" element={<Customers />} />
      <Route path="/addproduct" element={<AddProduct />} />
      <Route path="/shops" element={<Shops />} /> */}
    
      {/* Meesho Routes */}
      {/* <Route path="/meeshocategories" element={<MeeshoCategories />} />
      <Route path="/meeshoproducts" element={<MeeshoProducts />} />
      <Route path="/meeshoproductsearch" element={<MeeshoSearchProduct />} />
      <Route path="/meeshoadjustprice" element={<MeeshoAdjustPrice />} />
      <Route path="/meeshoproducthot" element={<MeeshoHotProduct />} />
      <Route path="/meeshoproductvaluable" element={<MeeshoValueProduct />} />
      <Route path="/meeshoorders" element={<MeeshoOrders />} />
      <Route path="/meeshocustomers" element={<MeeshoCustomers />} />
      <Route path="/meeshoaddproduct" element={<MeeshoAddProduct />} />
      <Route path="/meeshoshops" element={<MeeshoShops />} /> */}

      {/* CJ Routes */}

      <Route path="/cjcategories" element={<CJCategories />} />
      <Route path="/cjaddproduct" element={<CJAddProduct />} />
      <Route path="/cjproducts" element={<CJProducts />} />
      <Route path="/cjadjustprice" element={<CJAdjustPrice />} />
      <Route path="/cjproductsearch" element={<CJSearchProduct />} />
      <Route path="/cjproducthot" element={<CJHotProduct />} />
      <Route path="/cjproductvaluable" element={<CJValueProduct />} />
      {/* <Route path="/cjorders" element={<MeeshoOrders />} />
      <Route path="/cjcustomers" element={<MeeshoCustomers />} />
      <Route path="/cjshops" element={<MeeshoShops />} /> */}

      {/* Baap Routes */}
      
      {/* <Route path="/baapcategories" element={<BaapCategories />} />
      <Route path="/baapproducts" element={<BaapProducts />} />
      <Route path="/baapproductsearch" element={<BaapSearchProduct />} />
      <Route path="/baapadjustprice" element={<BaapAdjustPrice />} />
      <Route path="/baapproducthot" element={<BaapHotProduct />} />
      <Route path="/baapproductvaluable" element={<BaapValueProduct />} />
      <Route path="/baaporders" element={<BaapOrders />} />
      <Route path="/baapcustomers" element={<BaapCustomers />} />
      <Route path="/baapaddproduct" element={<BaapAddProduct />} />
      <Route path="/baapshops" element={<BaapShops/>} /> */}

      {/* Deodap Routes */}
      
      {/* <Route path="/deodapcategories" element={<DeodapCategories />} />
      <Route path="/deodapproducts" element={<DeodapProducts />} />
      <Route path="/deodapproductsearch" element={<DeodapSearchProduct />} />
      <Route path="/deodapadjustprice" element={<DeodapAdjustPrice />} />
      <Route path="/deodapproducthot" element={<DeodapHotProduct />} />
      <Route path="/deodapproductvaluable" element={<DeodapValueProduct />} />
      <Route path="/deodaporders" element={<DeodapOrders />} />
      <Route path="/deodapcustomers" element={<DeodapCustomers />} />
      <Route path="/deodapaddproduct" element={<DeodapAddProduct />} /> */}
  
  
      <Route path="/calendar" element={<Calander/>}/>
      <Route path="/kanban" element={<Kanban/>}/>
      <Route path="/email" element={<Email/>}/>
      <Route path='/phonenumbers' element={<Contacts />}/>
      <Route path='/bills' element={<Bills />}/>
      <Route path='/analytics' element={<Analytics />}/>
      <Route path='/banking' element={<Banking />}/>
      <Route path='/userlist' element={<UsersList />}/>
      <Route path='/useradd' element={<UserAdd />}/>
      <Route path='/useredit' element={<EditUser />}/>
      <Route path='/userfind' element={<FindUser />}/>
      <Route path='/instagramUsers' element={<InstagramMarket />}/>
      <Route path='/facebookUsers' element={<FacebookMarket />}/>
      <Route path='/treeView' element={<TreeView />}/>

      {/* Rooftop */}
      <Route path='/registersolarshop' element={<AddRooftopShop />}/>
      <Route path="/shopslist" element={<RooftopList/>}/>
      <Route path='/solarshopdetails' element={<RooftopShopdetails />}/>
      <Route path='/editsolarshop' element={<RooftopEdit />} />
      <Route path='/addshopproduct' element={<RooftopProducts />}/>
      <Route path='/shopsproductList' element={<RooftopProductsList />}/>

      {/* Rooftop Merchant*/}
      <Route path='/greentransactions' element={<GreenTransactions />}/>
      <Route path="/coupons" element={<CouponsRooftop/>}/>
      <Route path="/couponslist" element={<CouponsList/>}/>
      <Route path="/couponsassign" element={<CouponsAssign/>}/>
      <Route path='/rooftopNotifications' element={<NotificationsRooftop />}/>
      <Route path='/purchaseProducts' element={<PurchaseProduct />}/>

      {/* Profile  */}
      <Route exact path="/userProfile" element={<Profile />} />
      <Route exact path="/userWallet" element={<Wallet />} />
      <Route exact path="/changePassword" element={<ChangePassword />} />

      {/* Emart Tabs */}

      <Route exact path="/emartuserfind" element={<EmartFindUser />} />
      <Route exact path="/emartorders" element={<EmartOrders />} />
      <Route exact path="/emartnewsletter" element={<NewsLetter />} />
      <Route exact path="/emartcontact" element={<Contactinfo />} />

      {/* Commission Tabs */}

      <Route exact path="/rooftopPayments" element={<ShopCommission />}/>
      <Route exact path="/greenPayments" element={<GreenCommission />}/>
      <Route exact path="/purchasePayments" element={<MonthlyCommission />}/>


    </Routes>
  )
}

export default Routers