import React, { useEffect, useState } from "react";
import HeaderComp from "../../Components/HeaderComp";
import { deleteNewsLetter, getNewsLetter, sendNewsLetter } from "../../api/EmartApi";

const NewsLetter = () => {
  const [newsLetter, setNewsLetter] = useState();
  const [data, setData] = useState('');
  const [subject, setSubject] = useState('');

  const getNewsRequest = async () => {
    const data = await getNewsLetter();
    setNewsLetter(data.contactList);
  };

  const handleDeleteRequest = async (id) => {
    const res = await deleteNewsLetter(id);
    getNewsLetter();
    alert(res.message);
  };

  const sendNewsRequest = async () => {
    const res = await sendNewsLetter(subject, data);
    setData('');
    setSubject('');
    alert(res.message);
  }

  useEffect(() => {
    getNewsRequest();
  }, [newsLetter]);
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="News Letter" />

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">News Letter </h4>
                  <p>People subscribed for News Letter </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Send Data</h4>
                <div className="input-group">
                  <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
                <div className="input-group" style={{marginTop: '2rem'}}>
                  <textarea className="form-control" id="customFile" rows="15" onChange={e => setData(e.target.value)} value={data}></textarea>
                </div>
                <div style={{ marginTop: "2rem" }}>
                  <button className="btn btn-primary" type="submit" onClick={sendNewsRequest}>
                    Upload Data
                  </button>
                </div>
               
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Sno</th>
                          <th>Email ID</th>
                          <th>Remove from List</th>
                        </tr>
                      </thead>
                      <tbody>
                        {newsLetter?.map((letter, ind) => {
                          return (
                            <tr key={ind+1}>
                              <td>{ind + 1}</td>
                              <td>{letter.merci_email}</td>
                              <td>
                                <button
                                  onClick={() =>
                                    handleDeleteRequest(letter._id)
                                  }
                                  type="button"
                                  className="btn btn-danger waves-effect waves"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
