import React, { useState, useEffect } from "react";
import HeaderComp from "../../Components/HeaderComp";
import {
  checkPhoneNumber,
  checkPancard,
  createUser,
} from "../../api/checkValidUser";
import { useSelector } from "react-redux";

const UserAdd = () => {
  const user = useSelector((state) => state.user.user);
  console.log(user);
  const fields = {
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    password: "Merci@1234",
    phone: "",
    street_one: "",
    street_two: "",
    village: "",
    mandal: "",
    district: "",
    state: "",
    pincode: "",
    gender: "",
    dob: "",
    qualification: "",
    level: "",
    pancard: "",
    aadhar: "",
    refer: "",
  };
  const [userForm, setUserForm] = useState(fields);
  const [phoneAvailable, setPhoneAvailable] = useState("");
  const [panAvailable, setPanAvailable] = useState("");

  const onChangeForm = (e) => {
    let val;
    if (e.target.name === "phone") {
      val = e.target.value.slice(0, 10);
    } else {
      val = e.target.value;
    }
    setUserForm((prevState) => {
      return { ...prevState, [e.target.name]: val };
    });
  };

  const onCheckWithPhone = async (e) => {
    const phonechk = await checkPhoneNumber(e.target.value);
    if (phonechk.status === 400) {
      return setPhoneAvailable(phonechk.message);
    }
    return setPhoneAvailable("");
  };

  const onCheckWithPancard = async (e) => {
    const panchk = await checkPancard(e.target.value);
    if (panchk.status === 400) {
      return setPanAvailable(panchk.message);
    }
    return setPanAvailable("");
  };

  const onFormSubmit = async () => {
    const updatedUserForm = { ...userForm, refer: user.merci_refer_id || 'MERTECH0000' };
    const addedUser = await createUser(updatedUserForm);
    if (addedUser.status === 200) {
      alert("Member has been created Succesfully");
      // onChangeAddUser();
    } else {
      alert("Please Try again, there is an issue creating the Member");
    }
  };

  const getLevelOptions = () => {
    if (user.merci_level === "Level 1") {
      return (
        <>
          <option value="Level 1"> Super Admin</option>
          <option value="Level 2"> Zonal Manager</option>
          <option value="Level 3"> Division Manager</option>
          <option value="Level 4"> Agent </option>
        </>
      );
    }

    if (user.merci_level === "Level 2") {
      return (
        <>
          <option value="Level 3"> Division Manager</option>
          <option value="Level 4"> Agent </option>
        </>
      );
    }

    if (user.merci_level === "Level 3") {
      return (
        <>
          <option value="Level 4"> Agent </option>
        </>
      );
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Add a New User" />
          <div
            className="row"
            style={{ backgroundColor: "#FFF", padding: "10px" }}
          >
            <div className="col-lg-12">
              <div className="white_card card_height_100 mb_30">
                <div className="white_card_body">
                  <div className="card-body">
                    {/* Name Field  start   */}
                    <h5 className="m-0">Full Name</h5>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label className="form-label" htmlFor="inputEmail4">
                          First Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          name="first_name"
                          required
                          value={userForm.first_name}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                      <div className=" col-md-4">
                        <label className="form-label" htmlFor="inputPassword4">
                          Middle Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Middle Name"
                          name="middle_name"
                          value={userForm.middle_name}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                      <div className=" col-md-4">
                        <label className="form-label" htmlFor="inputPassword4">
                          Last Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          name="last_name"
                          value={userForm.last_name}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>

                    {/* Name Field  End   */}
                    {/* Phone Email Password  start   */}
                    <h5 className="m-0">Phone & Email</h5>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          value={userForm.email}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                      <div className=" col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Phone<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone Number"
                          name="phone"
                          value={userForm.phone}
                          onChange={(e) => onChangeForm(e)}
                          onBlur={(e) => onCheckWithPhone(e)}
                        />
                        <p style={{ color: "red" }}>{phoneAvailable}</p>
                      </div>
                    </div>
                    {/* Phone Email  End   */}
                    {/* Street Start  */}
                    <h5 className="m-0">Address</h5>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Flat No / House No
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Flat No / House No"
                          name="street_one"
                          value={userForm.street_one}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                      <div className=" col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Street Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Street Name"
                          name="street_two"
                          value={userForm.street_two}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className=" col-md-4">
                        <label className="form-label" htmlFor="inputCity">
                          Village
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Village"
                          name="village"
                          value={userForm.village}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                      <div className=" col-md-4">
                        <label className="form-label" htmlFor="inputCity">
                          Mandal
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mandal"
                          name="mandal"
                          value={userForm.mandal}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                      <div className=" col-md-4">
                        <label className="form-label" htmlFor="inputCity">
                          District
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="District"
                          name="district"
                          value={userForm.district}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          State
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="State"
                          name="state"
                          value={userForm.state}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                      <div className=" col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Pincode
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Pincode"
                          name="pincode"
                          value={userForm.pincode}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>
                    {/* Stree End */}
                    {/* Gender and Dob End */}
                    <h5 className="m-0">Gender & DOB</h5>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Gender
                        </label>
                        <select
                          id="inputState"
                          className="form-control"
                          name="gender"
                          value={userForm.gender}
                          onChange={(e) => onChangeForm(e)}
                        >
                          <option selected="">Choose...</option>
                          <option value="Male"> Male </option>
                          <option value="Female"> Female </option>
                          <option value="Other"> Other </option>
                        </select>
                      </div>
                      <div className=" col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Date Of Birth
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="inputDate"
                          name="dob"
                          value={userForm.dob}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>
                    <h5 className="m-0">Qualification</h5>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Qualification"
                          name="qualification"
                          value={userForm.qualification}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>

                    <h5 className="m-0">
                      Level<span style={{ color: "red" }}>*</span>
                    </h5>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <select
                          id="inputState"
                          className="form-control"
                          name="level"
                          value={userForm.level}
                          onChange={(e) => onChangeForm(e)}
                        >
                          <option selected="">Choose...</option>
                          {getLevelOptions()}
                        </select>
                      </div>
                    </div>

                    <h5 className="m-0">Pancard & Aadhar Card</h5>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Pancard<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Pancard"
                          name="pancard"
                          value={userForm.pancard}
                          onChange={(e) => onChangeForm(e)}
                          onBlur={(e) => onCheckWithPancard(e)}
                        />
                        <span style={{ color: "red" }}>{panAvailable}</span>
                      </div>
                      <div className=" col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Aadhar Card
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Aadhar Card"
                          name="aadhar"
                          value={userForm.aadhar}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>

                    <h5 className="m-0">
                      Refered By<span style={{ color: "red" }}>*</span>
                    </h5>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <input
                          type="text"
                          className="form-control"
                          value={user.merci_refer_id}
                          disabled=""
                        />
                      </div>
                    </div>

                    <hr />

                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={onFormSubmit}
                    >
                      Create User
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAdd;
