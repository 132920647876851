import React from 'react'
import HeaderComp from '../../Components/HeaderComp'

const FacebookMarket = () => {
  return (
    <div className="main-content">
    <div className="page-content">
      <div className="container-fluid">
        <HeaderComp title="Facebook Users" />
        <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Facebook User</h4>
                  <p className="card-title-desc">
                   Add Facebook User to Merciplus Infra
                  </p>

                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Facbook ID"
                        id="example-search-input"
                        // onChange={(e) => setSkucode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Full Name"
                        id="example-search-input"
                        // onChange={(e) => setSkucode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Email ID"
                        id="example-search-input"
                        // onChange={(e) => setSkucode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Phone Number"
                        id="example-search-input"
                        // onChange={(e) => setSkucode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn btn-primary"
                     // onClick={() => updateProduct(true)}
                      type="submit"
                    >
                      Add Facebook User
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Facebook Users List</h4>

                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Refer ID</th>
                          <th>Facebook ID</th>
                          <th>Full Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Credits Gathered</th>
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                       
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  )
}

export default FacebookMarket