import React, { useEffect, useState } from 'react'
import { deleterooftopProduct, getrooftopProduct } from '../../api/RooftopApi';
import HeaderComp from '../../Components/HeaderComp';

const RooftopProductsList = () => {
    const [shops, setShops] = useState([]);
    
    useEffect(() => {
      getUsers();
    }, []);
  
    const getUsers = async () => {
      const shopsList = await getrooftopProduct();
      setShops(shopsList);
    };
  
    const onCheckChange = async (id) => {
      const res = await deleterooftopProduct(id);
      alert(res.message);
      window.location.reload();
    }
  
    return (
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <HeaderComp title="List of Rooftop Shops" />
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Products List</h4>
  
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {shops &&
                            shops.map((shop, index) => {
                              return (
                                <tr key={index}>
                                  <td>{shop.merci_title}</td>
                                  <td>{shop.merci_description}</td>
                                  <td>{shop.merci_price}</td>
                                  <td>{shop.merci_quantity}</td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-danger waves-effect waves-light"
                                      onClick={() => onCheckChange(shop._id)}
                                    >
                                      <i className="ri-close-line align-middle me-2"></i>{" "}
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default RooftopProductsList