import React from "react";
import HeaderComp from "../HeaderComp";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

const Calander = () => {
  const events = [{ title: "Meeting", start: new Date() }];

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Calendar" />
          <div className="row mb-4">
            <div className="col-xl-3">
              <div className="card h-100">
                <div className="card-body">
                  <button
                    type="button"
                    className="btn font-16 btn-primary waves-effect waves-light w-100"
                    id="btn-new-event"
                    data-bs-toggle="modal"
                    data-bs-target="#event-modal"
                  >
                    Create New Event
                  </button>

                  <div id="external-events">
                    <br />
                    <p className="text-muted">
                      Drag and drop your event or click in the calendar
                    </p>
                    <div
                      className="external-event fc-event bg-success"
                      data-className="bg-success"
                    >
                      <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2"></i>
                      New Event Planning
                    </div>
                    <div
                      className="external-event fc-event bg-info"
                      data-className="bg-info"
                    >
                      <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2"></i>
                      Meeting
                    </div>
                    <div
                      className="external-event fc-event bg-warning"
                      data-className="bg-warning"
                    >
                      <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2"></i>
                      Generating Reports
                    </div>
                    <div
                      className="external-event fc-event bg-danger"
                      data-className="bg-danger"
                    >
                      <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2"></i>
                      Create New theme
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9">
              <div className="card mb-0">
                <div className="card-body">
                  <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    events={events}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ clear: "both" }}></div>
        </div>
      </div>
    </div>
  );
};

export default Calander;
