import React, { useEffect, useState } from 'react'
import HeaderComp from '../../Components/HeaderComp'
import { getContact, sendContact } from '../../api/EmartApi';
import Popup from '../../Components/Popup/Popup';

const Contactinfo = () => {

  const [contact, setContact] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState('');
  const [subject, setSubject] = useState('');
  const [id, setId] = useState('');
  const [replyEmail, setReplyEmail] = useState('');

  const getContactRequest = async () => {
    const data = await getContact();
    setContact(data.contactList);
  };

  const formatDate = (date) => {
    const dateVal = new Date(date);
    return `${dateVal.getDay()}/${dateVal.getMonth()}/${dateVal.getFullYear()}`
  }

  const handlePopup = (id, email) => {
    setIsOpen(true);
    setId(id);
    setReplyEmail(email);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const handleReply = async () => {
    const res = await sendContact(id, replyEmail, subject, data);
    getContactRequest();
    setIsOpen(false);
    setSubject('');
    setData('');
    alert(res.message);
  }

  useEffect(() => {
    getContactRequest();
  },[]);

  return (
    <div className="main-content">
    <div className="page-content">
      <div className="container-fluid">
        <HeaderComp title="Contcat Info" />

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Contact Info </h4>
                <p>People messages from contact info</p>
                {/* <div className="row mb-3">
                  <label
                    for="example-text-input"
                    className="col-sm-2 col-form-label"
                  >
                    Member
                  </label>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Refer ID, Email or Phone Number"
                      id="example-text-input"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email ID</th>
                          <th>Phone Number</th>
                          <th>Subject</th>
                          <th>Message</th>
                          <th>Reply</th>
                          <th>Status</th>
                          <th>Send Reply</th>
                          <th>Received Date</th>
                          <th>Updated Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contact?.map((letter, ind) => {
                          return (
                            <tr>
                              <td>{letter.merci_name}</td>
                              <td>{letter.merci_email}</td>
                              <td>{letter.merci_phone}</td>
                              <td>{letter.merci_subject}</td>
                              <td>{letter.merci_description}</td>
                              <td>{letter.merci_response}</td>
                              <td>{letter.merci_isOpen ? 'Closed' : 'Open'}</td>
                              <td><button
                                  onClick={() =>
                                    handlePopup(letter._id, letter.merci_email)
                                  }
                                  type="button"
                                  className="btn btn-danger waves-effect waves"
                                >
                                  Send
                                </button></td>
                              <td>{formatDate(letter.createdAt)}</td>
                              <td>{formatDate(letter.updatedAt)}</td>
                              <td></td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            isOpen &&
            <Popup isOpen={isOpen} onClose={closePopup}>
            

              
                <h4>Send Reply</h4>

                <div className="col-12">
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Subject"
                        id="example-search-input"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                    
                  </div>
                </div>

                <div className="col-12">
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <textarea
                        className="form-control"
                        type="search"
                        id="example-search-input"
                        value={data}
                        onChange={(e) => setData(e.target.value)}
                      >Enter Message</textarea>
                    </div>
                    
                  </div>
                </div>

                
               
                <div>
                      <button
                        className="btn btn-primary"
                        onClick={handleReply}
                        type="submit"
                      >
                        Send Reply
                      </button>
                    </div>
              
          </Popup>
          }
      </div>
    </div>
  </div>
  )
}

export default Contactinfo