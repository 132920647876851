import React from "react";
import { rupee } from "../../constants/priceFormats";

const BalanceButtons = ({ arr }) => {
  return arr.map((ar, ind) => {
    return (
      <div
        className="dropdown d-none d-lg-inline-block ms-1"
        style={{ borderLeftStyle: "solid"}}
        key={ind}
      >
        <button
          type="button"
          className="btn header-item noti-icon waves-effect"
          data-toggle="fullscreen"
        >
          <p style={{ marginBottom: "0rem" }}>{ar.title}</p>
          <p> {rupee.format(ar.value)}</p>
        </button>
      </div>
    );
  });
};

export default BalanceButtons;
