import axios from 'axios';
import { COUPONURL } from '../constants/constants';


export const getCouponsByMerchantId = async (merchantId) => {
    try {
        const response = await axios.get(`${COUPONURL}/merchant/${merchantId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching coupons by merchantId:', error);
        throw error;
    }
};

export const getCouponsByUserId = async (userId) => {
    try {
        const response = await axios.get(`${COUPONURL}/user/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching coupons by userId:', error);
        throw error;
    }
};

export const updateCouponAssignment = async (couponId, assignedTo, assignedId) => {
    try {
        const response = await axios.put(`${COUPONURL}/assign/${couponId}`, { assignedTo, assignedId });
        return response.data;
    } catch (error) {
        console.error('Error updating coupon assignment:', error);
        throw error;
    }
};

export const updateCouponUsage = async (couponId, used) => {
    try {
        const response = await axios.put(`${COUPONURL}/used/${couponId}`, { used });
        return response.data;
    } catch (error) {
        console.error('Error updating coupon usage:', error);
        throw error;
    }
};

export const insertMultipleCoupons = async (coupons, amount, merchantId) => {
    try {
        const response = await axios.post(`${COUPONURL}/multiple`, { coupons, amount, merchantId });
        return response.data;
    } catch (error) {
        console.error('Error inserting multiple coupons:', error);
        throw error;
    }
};