import React, { useState, useEffect } from "react";
import HeaderComp from "../../Components/HeaderComp";
import {
  getCJCategoryList,
  deleteCJProduct,
} from "../../api/CJApi";
import Loader from "../../Utils/Loader";
import { getPercentageList } from "../../api/PercentageApi";
import { rupee, USDollar } from "../../constants/priceFormats";
import { getDiscountPercentage, getPercentage } from "../../Utils/Percentages";
import CategoryListAll from "../../Components/CategoryList/CategoryListAll";
import SortProducts from "../../Components/SortProducts/SortProducts";
import ProductPagination from "../../Components/Pagination/ProductPagination";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../features/products/productSlice";

const CJProducts = () => {
  const [prodlist, setProdlist] = useState([]);
  const [catlist, setCatlist] = useState([]);
  const [catMainlist, setCatMainlist] = useState([]);
  const [mainCat, setMainCat] = useState("");
  const [subCat, setSubCat] = useState("");
  const [lowCat, setLowCat] = useState("");
  const [active, setActive] = useState(0);
  const [loader, setLoader] = useState(false);
  const [percentage, setPercentage] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);

  const dispatch = useDispatch();
  const productsRed = useSelector((state) => state.product);

  const [mainlist, setmainlist] = useState([]);
  const [mainselect, setmainselect] = useState("");
  const [sublist, setsublist] = useState([]);
  const [subselect, setsubselect] = useState("");
  const [lowlist, setlowlist] = useState([]);
  const [lowselect, setlowselect] = useState("");

  const onMainSelect = (e) => {
    const sublist = catlist.filter((data) => data.main_cat === e.target.value);
    const sublistfilter = [...new Set(sublist?.map((data) => data.sub_cat))];
    setmainselect(e.target.value);
    setsublist(sublistfilter);
    setsubselect("");
    setlowselect("");
  };

  const onSubSelect = (e) => {
    const lowlist = catlist.filter(
      (data) => data.main_cat === mainselect && data.sub_cat === e.target.value
    );
    const lowlistfilter = [...new Set(lowlist?.map((data) => data.low_cat))];
    setsubselect(e.target.value);
    setlowlist(lowlistfilter);
    setlowselect("");
  };

  const onLowSelect = (e) => {
    setlowselect(e.target.value);
  };

  const getCatList = async () => {
    const list = await getCJCategoryList();
    setCatlist(list.categorylist);
    const mainlistfilter = [
      ...new Set(list?.categorylist?.map((data) => data.main_cat)),
    ];
    setmainlist(mainlistfilter);
  };
  // const [cate, setCate] = useState({maincat: 'Main Category', subcat: 'Sub Category', lowcat: 'Low Category'});

  const getSelectedCategories = async () => {
    setLoader(true);
    dispatch(
      getProducts({
        company: "cj",
        main: mainselect,
        sub: subselect,
        low: lowselect,
        page: 1,
        pageSize: 40,
      })
    );
    setLoader(false);
  };

  useEffect(() => {
    getCatList();
    getPercentages();
  }, [prodlist]);

  const updateProductsList = (val) => {
    setLoader(true);
    setActive(val);
    if (val === 0) {
      setProdlist(catMainlist);
    }
    if (val === 1) {
      setProdlist(prodlist.filter((el) => el.merci_ishot === true));
    }
    if (val === 2) {
      setProdlist(prodlist.filter((el) => el.merci_isValuable === true));
    }
    setLoader(false);
  };

  const getPercentages = async () => {
    const percentValues = await getPercentageList("cj");
    setPercentage(percentValues.percentageList[0]);
  };

  const handleDelete = async (sku) => {
    const result = await deleteCJProduct(sku);
    alert(result.message);
    getSelectedCategories(mainselect, subselect, lowselect);
  };

  const handleIncrement = async () => {
    if (page < productsRed?.products.totalPages) {
      setPage(page + 1);
      setLoader(true);
      dispatch(
        getProducts({
          company: "cj",
          main: mainselect,
          sub: subselect,
          low: lowselect,
          page: page+1,
          pageSize: 40,
        })
      );
      setLoader(false);
    }
  };

  const handleDecrement = async () => {
    if (page > 1) {
      setPage(page - 1);
      setLoader(true);
      dispatch(
        getProducts({
          company: "cj",
          main: mainselect,
          sub: subselect,
          low: lowselect,
          page: page-1,
          pageSize: 40,
        })
      );
      setLoader(false);
    }
  };

  const handlePageClick = async (pageset) => {
    if (page !== pageset) {
      setPage(pageset);
      setLoader(true);
      dispatch(
        getProducts({
          company: "meesho",
          main: mainselect,
          sub: subselect,
          low: lowselect,
          page: pageset,
          pageSize: 40,
        })
      );
      setLoader(false);
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Product" />
          <CategoryListAll
            onMainSelect={onMainSelect}
            mainlist={mainlist}
            onSubSelect={onSubSelect}
            sublist={sublist}
            onLowSelect={onLowSelect}
            lowlist={lowlist}
            getSelectedCategories={getSelectedCategories}
          />

          <div className="row">
            <div className="col-lg-13">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <h5>{}</h5>
                          <ol className="breadcrumb p-0 bg-transparent mb-2">
                            <li className="breadcrumb-item">{mainselect}</li>
                            <li className="breadcrumb-item">{subselect}</li>
                            <li className="breadcrumb-item">{lowselect}</li>
                          </ol>
                        </div>
                      </div>
                    </div>

                    <SortProducts
                      totalProducts={(productsRed.products.company === 'cj' && productsRed?.products.totalProducts) || ''}
                      active={active}
                      updateProductsList={updateProductsList}
                    />

                    {loader || productsRed?.loading ? (
                      <Loader />
                    ) : (
                      <>
                        <div className="row g-0">
                          {productsRed.products.company === 'cj' && productsRed?.products.productslist?.map((prod, index) => {
                            return (
                              <div
                                className="col-xl-3 col-sm-6"
                                key={index}
                                style={{ height: "700px" }}
                              >
                                <div
                                  className="product-box"
                                  style={{ height: "700px" }}
                                >
                                  <div className="product-img">
                                    <div className="product-ribbon badge bg-warning">
                                      {prod.merci_isValuable &&
                                        "Valued Product"}
                                    </div>
                                    <div
                                      className="product-ribbon badge bg-danger"
                                      style={{ marginTop: "2rem" }}
                                    >
                                      {prod.merci_ishot && "Hot Product"}
                                    </div>
                                    {/* <div className="product-like">
                                    { prod.merci_isValuable && <i className="mdi mdi-heart text-danger"></i> }
                            </div> */}
                                    <div className="product-like"></div>
                                    <img
                                      src={prod.merci_prod_img}
                                      alt="img-1"
                                      className="img-fluid mx-auto d-block img-figure"
                                    />
                                  </div>

                                  <div className="text-center">
                                    <p className="font-size-12 mb-1">
                                      {prod.merci_supplier}
                                    </p>
                                    <h5 className="font-size-15">
                                      <a href="#" className="text-dark">
                                        {prod.merci_prod_name}
                                      </a>
                                    </h5>
                                    <table className="ui table">
                                      <thead>
                                        <tr>
                                          <td>Indian</td>
                                          <td>US</td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <h5 className="mt-3 mb-0 font-size-15">
                                              &#8377;{" "}
                                              {prod.merci_mrp.split(" -- ")
                                                .length > 1
                                                ? rupee.format(
                                                    parseFloat(
                                                      prod.merci_mrp.split(
                                                        " -- "
                                                      )[1]
                                                    ) * 85
                                                  )
                                                : rupee.format(
                                                    parseFloat(prod.merci_mrp) *
                                                      85
                                                  )}
                                            </h5>
                                          </td>
                                          <td>
                                            <h5 className="mt-3 mb-0 font-size-15">
                                              {prod.merci_mrp.split(" -- ")
                                                .length > 1
                                                ? USDollar.format(
                                                    prod.merci_mrp.split(
                                                      " -- "
                                                    )[1]
                                                  )
                                                : USDollar.format(
                                                    prod.merci_mrp
                                                  )}
                                            </h5>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <h5 className="mt-3 mb-0 font-size-15">
                                              {prod.merci_merci_discount}%
                                            </h5>
                                          </td>
                                          <td>
                                            <h5 className="mt-3 mb-0 font-size-15">
                                              {prod.merci_us_discount}%
                                            </h5>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <h5 className="mt-3 mb-0 font-size-15">
                                              &#8377;{" "}
                                              {getPercentage(
                                                prod.merci_mrp.split(" -- ")
                                                  .length > 1
                                                  ? parseFloat(
                                                      prod.merci_mrp.split(
                                                        " -- "
                                                      )[1]
                                                    ) * 85
                                                  : parseFloat(prod.merci_mrp) *
                                                      85,
                                                parseInt(
                                                  prod.merci_merci_discount
                                                )
                                              )}
                                            </h5>
                                          </td>
                                          <td>
                                            <h5 className="mt-3 mb-0 font-size-15">
                                              ${" "}
                                              {getPercentage(
                                                prod.merci_mrp.split(" -- ")
                                                  .length > 1
                                                  ? parseFloat(
                                                      prod.merci_mrp.split(
                                                        " -- "
                                                      )[1]
                                                    )
                                                  : parseFloat(prod.merci_mrp),
                                                parseInt(prod.merci_us_discount)
                                              )}
                                            </h5>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <h5 className="mt-3 mb-0 font-size-15">
                                              (
                                              {
                                                getDiscountPercentage(
                                                  percentage,
                                                  prod.merci_mrp.split(" -- ")
                                                    .length > 1
                                                    ? parseFloat(
                                                        prod.merci_mrp.split(
                                                          " -- "
                                                        )[1]
                                                      ) * 85
                                                    : parseFloat(
                                                        prod.merci_mrp
                                                      ) * 85,
                                                  parseInt(
                                                    prod.merci_merci_discount
                                                  )
                                                ).reduced
                                              }
                                              %):- &#8377;{" "}
                                              {
                                                getDiscountPercentage(
                                                  percentage,
                                                  prod.merci_mrp.split(" -- ")
                                                    .length > 1
                                                    ? parseFloat(
                                                        prod.merci_mrp.split(
                                                          " -- "
                                                        )[1]
                                                      ) * 85
                                                    : parseFloat(
                                                        prod.merci_mrp
                                                      ) * 85,
                                                  parseInt(
                                                    prod.merci_merci_discount
                                                  )
                                                ).finalValue
                                              }
                                            </h5>
                                          </td>
                                          <td>
                                            <h5 className="mt-3 mb-0 font-size-15">
                                              (
                                              {
                                                getDiscountPercentage(
                                                  percentage,
                                                  prod.merci_mrp.split(" -- ")
                                                    .length > 1
                                                    ? parseFloat(
                                                        prod.merci_mrp.split(
                                                          " -- "
                                                        )[1]
                                                      )
                                                    : parseFloat(
                                                        prod.merci_mrp
                                                      ),
                                                  parseInt(
                                                    prod.merci_us_discount
                                                  )
                                                ).reduced
                                              }
                                              %):- ${" "}
                                              {
                                                getDiscountPercentage(
                                                  percentage,
                                                  prod.merci_mrp.split(" -- ")
                                                    .length > 1
                                                    ? parseFloat(
                                                        prod.merci_mrp.split(
                                                          " -- "
                                                        )[1]
                                                      )
                                                    : parseFloat(
                                                        prod.merci_mrp
                                                      ),
                                                  parseFloat(
                                                    prod.merci_us_discount
                                                  )
                                                ).finalValue
                                              }
                                            </h5>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>

                                    <button
                                      className="btn btn-danger button-remove"
                                      onClick={() =>
                                        handleDelete(prod.merci_spu_id)
                                      }
                                      type="submit"
                                    >
                                      Remove Product
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {productsRed.products.company === 'cj' && productsRed?.products.totalPages > 1 && (
                          <ProductPagination
                            page={page}
                            totalPages={productsRed?.products.totalPages}
                            handleDecrement={handleDecrement}
                            handleIncrement={handleIncrement}
                            handlePageClick={handlePageClick}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CJProducts;
