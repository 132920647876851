import React, { useState, useEffect } from "react";
import HeaderComp from "../../Components/HeaderComp";
import CategoryList from "../../Components/CategoryList/CategoryList";
import {
  setCJPriceUpdate,
  getCJCategoryList,
  setCJUSPriceUpdate,
  setSingleCJPriceUpdate,
} from "../../api/CJApi";
import Loader from "../../Utils/Loader";
import { addPercentageList, getPercentageList } from "../../api/PercentageApi";

const CJAdjustPrice = () => {
  const [catlist, setCatlist] = useState([]);
  const [mainlist, setmainlist] = useState([]);
  const [mainselect, setmainselect] = useState("");
  const [sublist, setsublist] = useState([]);
  const [subselect, setsubselect] = useState("");
  const [lowlist, setlowlist] = useState([]);
  const [lowselect, setlowselect] = useState("");
  const [changed, setChanged] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderUS, setLoaderUS] = useState(false);
  const [price, setPrice] = useState("");
  const [usprice, setUSPrice] = useState("");
  const [skuCode, setSkuCode] = useState("");
  const [percentage, setPercentage] = useState({});

  const getCatList = async () => {
    const list = await getCJCategoryList();
    setCatlist(list.categorylist);

    const mainlistfilter = [
      ...new Set(list?.categorylist?.map((data) => data.main_cat)),
    ];
    setmainlist(mainlistfilter);
  };
  const onMainSelect = (e) => {
    const sublist = catlist.filter((data) => data.main_cat === e.target.value);
    const sublistfilter = [...new Set(sublist?.map((data) => data.sub_cat))];
    setmainselect(e.target.value);
    setsublist(sublistfilter);
    //  getSelectedCategories(e.target.value, '', '');
  };

  const onSubSelect = (e) => {
    const lowlist = catlist.filter(
      (data) => data.main_cat === mainselect && data.sub_cat === e.target.value
    );
    const lowlistfilter = [...new Set(lowlist?.map((data) => data.low_cat))];
    setsubselect(e.target.value);
    setlowlist(lowlistfilter);
    //  getSelectedCategories(mainselect, e.target.value, '');
  };

  const onLowSelect = (e) => {
    setlowselect(e.target.value);
  };

  const getMainlist = () => {
    const mainlistfilter = [...new Set(catlist?.map((data) => data.main_cat))];
    setmainlist(mainlistfilter);
    setChanged(!changed);
  };
  
  const getPercentages = async () => {
    const percentValues = await getPercentageList("cj");
    setPercentage(percentValues.percentageList[0]);
  };

  useEffect(() => {
    getCatList();
    getMainlist();
    getPercentages();
  }, []);

  const updatePrice = async () => {
    setLoader(true);
    if(skuCode === ''){
    const res = await setCJPriceUpdate(mainselect, subselect, lowselect, price);
    alert(res.message);
    setPrice("");
    } else {
      const res = await setSingleCJPriceUpdate(skuCode, price, 'in');
      alert(res.message);
      setPrice("");
      setSkuCode("");
    }
    setLoader(false);
  };

  const updateUSPrice = async () => {
    setLoaderUS(true);
    if(skuCode === ''){
    const res = await setCJUSPriceUpdate(
      mainselect,
      subselect,
      lowselect,
      usprice
    );
    alert(res.message);
    setUSPrice("");
    } else {
      const res = await setSingleCJPriceUpdate( skuCode, usprice, "us");
      alert(res.message);
      setUSPrice("");
      setSkuCode("");
    }
    setLoaderUS(false);
  };

  
  const updatePercentage = async () => {
    const res = await addPercentageList('cj', percentage)
    alert(res.message);
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <HeaderComp title="Price Adjustment for Products" />
          <div className="row">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Merci Percentages</h4>
                <p className="card-title-desc">Adjust Merci Percentages</p>

                <div className="col-12">
                  <div className="row mb-3">
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Sub Low Minimum"
                        id="example-search-input"
                        value={percentage.sublowMin}
                        onChange={(e) => setPercentage({...percentage, sublowMin: e.target.value})}
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Sub Low Maximum"
                        id="example-search-input"
                        value={percentage.sublowMax}
                        onChange={(e) => setPercentage({...percentage, sublowMax: e.target.value})}
                      />
                    </div>

                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Sub Low Percentage"
                        id="example-search-input"
                        value={percentage.sublowPercent}
                        onChange={(e) => setPercentage({...percentage, sublowPercent: e.target.value})}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row mb-3">
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Low Minimum"
                        id="example-search-input"
                        value={percentage.lowMin}
                        onChange={(e) => setPercentage({...percentage, lowMin: e.target.value})}
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Low Maximum"
                        id="example-search-input"
                        value={percentage.lowMax}
                        onChange={(e) => setPercentage({...percentage, lowMax: e.target.value})}
                      />
                    </div>

                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Low Percentage"
                        id="example-search-input"
                        value={percentage.lowPercent}
                        onChange={(e) => setPercentage({...percentage, lowPercent: e.target.value})}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row mb-3">
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Mid Minimum"
                        id="example-search-input"
                        value={percentage.midMin}
                        onChange={(e) => setPercentage({...percentage, midMin: e.target.value})}
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Mid Maximum"
                        id="example-search-input"
                        value={percentage.midMax}
                        onChange={(e) => setPercentage({...percentage, midMax: e.target.value})}
                      />
                    </div>

                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Low Percentage"
                        id="example-search-input"
                        value={percentage.midPercent}
                        onChange={(e) => setPercentage({...percentage, midPercent: e.target.value})}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row mb-3">
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="High Minimum"
                        id="example-search-input"
                        value={percentage.highMin}
                        onChange={(e) => setPercentage({...percentage, highMin: e.target.value})}
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Low Maximum"
                        id="example-search-input"
                        value={percentage.highMax}
                        onChange={(e) => setPercentage({...percentage, highMax: e.target.value})}
                      />
                    </div>

                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Low Percentage"
                        id="example-search-input"
                        value={percentage.highPercent}
                        onChange={(e) => setPercentage({...percentage, highPercent: e.target.value})}
                      />
                    </div>
                  </div>
                </div>
                <div>
                      <button
                        className="btn btn-primary"
                        onClick={updatePercentage}
                        type="submit"
                      >
                        Update Percentages
                      </button>
                    </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Categories</h4>
                  <p className="card-title-desc">Categories List of CJ</p>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-0">
                        <label className="form-label">
                          Select Main Category
                        </label>
                        <select
                          className="form-control select2-search-disable"
                          onChange={(e) => onMainSelect(e)}
                        >
                          <option selected="">Select Main category</option>
                          {mainlist.map((main, index) => (
                            <option key={index} value={main}>
                              {main}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-0">
                        <label className="form-label">
                          Select Sub Category
                        </label>
                        <select
                          className="form-control select2-search-disable"
                          onChange={(e) => onSubSelect(e)}
                        >
                          <option selected="">Select Sub category</option>
                          {sublist.map((main, index) => (
                            <option key={index} value={main}>
                              {main}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-0">
                        <label className="form-label">
                          Select Low Category
                        </label>
                        <select
                          className="form-control select2-search-disable"
                          onChange={(e) => onLowSelect(e)}
                        >
                          <option selected="">Select Low category</option>
                          {lowlist.map((main, index) => (
                            <option key={index} value={main}>
                              {main}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Price Update for Single Product</h4>
                  <p className="card-title-desc">
                    Please Enter SKU Id of the Product to Increase
                  </p>

                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Please Enter SKU Id of the Product to Increase"
                        id="example-search-input"
                        onChange={(e) => setSkuCode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {loader ? (
              <Loader />
            ) : (
              <div className="col-6">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Price Update</h4>
                    <p className="card-title-desc">
                      Please Enter Percentage of the Product to Increase
                    </p>

                    <div className="row mb-3">
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="search"
                          placeholder="Enter % of Price to be Increased"
                          id="example-search-input"
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={updatePrice}
                        type="submit"
                      >
                        Update Price
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {loaderUS ? (
              <Loader />
            ) : (
              <div className="col-6">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">International Price Update</h4>
                    <p className="card-title-desc">
                      Please Enter Percentage of the Product to Increase for US
                      and Rest of the world
                    </p>

                    <div className="row mb-3">
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="search"
                          placeholder="Enter % of Price to be Increased"
                          id="example-search-input"
                          onChange={(e) => setUSPrice(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={updateUSPrice}
                        type="submit"
                      >
                        Update US Price
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CJAdjustPrice;
